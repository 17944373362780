import React, {
  FunctionComponent,
  ChangeEvent,
  useState,
  SyntheticEvent
} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { IconButton } from 'components/Buttons/IconButton';
import { useDispatch } from 'react-redux';
import { setSearchQuery } from 'features/search/searchSlice';

interface SearchFormProps {
  placeholder: string;
}

export const SearchForm: FunctionComponent<SearchFormProps> = (props) => {
  const [inputValue, setInputValue] = useState<string>('');
  const dispatch = useDispatch();

  const onReset = () => {
    setInputValue('');
    dispatch(setSearchQuery(''));
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    dispatch(setSearchQuery(inputValue));
  };

  return (
    <div className={styles['search-form']}>
      <form action="" onSubmit={onSubmit}>
        <input
          className={classNames(
            styles['search-form__input'],
            'form__input form__input__rounded'
          )}
          type="search"
          value={inputValue}
          onChange={onChange}
          placeholder={props.placeholder}
        />
        <IconButton
          label={'Search'}
          icon={'search'}
          type="submit"
          iconSize={20}
          noBackground={true}
        />
        {inputValue !== '' && (
          <IconButton
            label={'Clear search'}
            icon={'x'}
            type="button"
            iconSize={20}
            size={'mini'}
            noBackground={true}
            onClick={onReset}
          />
        )}
      </form>
    </div>
  );
};
