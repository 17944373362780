import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { FullImage } from 'components/Images/FullImage';
import { ChannelBanned } from 'features/channel/components/ChannelBanned/ChannelBanned';
import styles from './styles.module.scss';
import { ThumbImage } from 'components/Images/ThumbImage';
import { OffAir } from 'components/OffAir';
import { LogoImage } from 'components/LogoImage';
import { FollowButtons } from 'features/chat/components/FollowButtons';
import { SupportButton } from 'components/Support/SupportButton';
import { useCurrentUser } from 'features/current-user/hooks';
import classNames from 'classnames';
import { Share } from 'components/Share';
import { useChannelEmbedNavigation } from 'features/events/EventEmbed/eventEmbedHooks';
import { Loader } from 'components/Loader';

export const ChannelEmbed: FunctionComponent = () => {
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const channelisLoaded = useSelector(
    (state: RootState) => state.channel.isLoaded
  );
  const embedPlayerLoaded = useSelector(
    (state: RootState) => state.channel.embedPlayerLoaded
  );
  const unavailable = useSelector(
    (state: RootState) => state.channel.channelUnavailable
  );
  const currentUser = useCurrentUser();

  useChannelEmbedNavigation();

  if (!channel) {
    return null;
  }

  const removeBranding = channel.removeEmbedBranding;

  if (unavailable || !channel.liveEmbedPlayerEnabled) {
    return <ChannelBanned />;
  }

  if (!channelisLoaded || channel == null || !embedPlayerLoaded) {
    return <Loader fillComponent={true} />;
  }

  return (
    <div
      className={classNames(styles['channel-embed'], {
        'has-image': channel.artworkKey
      })}
    >
      <FullImage
        artworkMode={'channel'}
        className={styles['channel-embed__image']}
        type={'event'}
      />
      <div className={styles['channel-embed__content']}>
        <LogoImage
          size={'small'}
          hasFallback={!channel.logoKey}
          isLink={!removeBranding}
        >
          <ThumbImage
            artworkKey={channel.logoKey}
            artworkMode={'logo'}
            alt={`Logo for ${channel.username}`}
          />
        </LogoImage>
        <h1 className={styles['channel-embed__title']}>{channel.username}</h1>
        {removeBranding === false && (
          <>
            <div className={styles['channel-embed__links']}>
              <SupportButton showAslink />
              <FollowButtons
                showAslink
                selectedProfileId={channel.ownerId}
                followedIds={(currentUser && currentUser.followedUserIds) || []}
              />
            </div>
            <Share title={channel.username} label={''} showLabel={true} />
          </>
        )}
      </div>
      <OffAir />
    </div>
  );
};
