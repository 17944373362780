import React, { FunctionComponent, useEffect, useContext } from 'react';
import { Player } from 'components/Player';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useAudio } from 'features/audio/audio';
import { useLocation } from 'react-router-dom';
import { UserAgentContext } from 'features/user-agent/userAgent';

export const RecordingPlayer: FunctionComponent = () => {
  const { isNativeAppWebview, isListenerAppWebview } =
    useContext(UserAgentContext);
  const audioUrl = useSelector((state: RootState) => state.audio.url);
  const { audio, loadAudio, togglePlayPause } = useAudio();
  const location = useLocation() as any;

  useEffect(() => {
    // If audio already set bail early
    if (audio || isListenerAppWebview) {
      return;
    }

    // Load the audio on render so recording
    // controls are active for user
    if (audioUrl) {
      loadAudio({
        audioSrc: audioUrl,
        autoPlay: (location.state && location.state.autoPlay) || false,
        audioType: 'recording'
      });
    }
  }, [audioUrl, loadAudio, audio, location, isListenerAppWebview]);

  const onClick = () => {
    togglePlayPause();
  };

  if (isNativeAppWebview) {
    return null;
  }

  if (!audioUrl) {
    return null;
  }

  return <Player onClick={onClick} showsControls={true} type={'recording'} />;
};
