import { Collection } from 'features/collections/collectionsSlice';
import { cloudflareUrl } from 'utilities/cloudflareImageResizer';

export interface CollectionModel extends Collection {
  isPublic(): boolean;
  artworkUrl(): string;
}

export const compareById = (
  collection1: Collection,
  collection2: Collection
): number => {
  if (parseInt(collection1.id) < parseInt(collection2.id)) {
    return 1;
  } else {
    return -1;
  }
};

export const generateArtworkUrl = (key: string | null): any => {
  if (key != null) {
    return cloudflareUrl(key, {
      width: 800,
      quality: 80
    });
  } else {
    return '';
  }
};

export const CollectionModel = (collection: Collection): CollectionModel => {
  return {
    ...collection,
    isPublic() {
      return this.accessLevel == 'public';
    },
    artworkUrl() {
      return generateArtworkUrl(this.artworkKey);
    }
  };
};
