import {
  V3ChannelView,
  CurrentBroadcast as apiCurrentBroadcast
} from 'api/v3/types/channel_view';
import {
  AccesscodeDetails,
  ChannelDetails,
  ChannelLink,
  PaidChannelDetails
} from 'features/channel/channelSlice';
import { V3ChannelViewPrivate } from './types/channel_view_private';
import { V3ChannelSubscriptionPrices } from './types/channel_subscription_prices';
import { Broadcast } from 'features/broadcast/broadcastsSlice';
import { V3ChannelSubscriptionCheckoutSession } from './types/channel_subscription_checkout_session';

export async function apiV3GetChannel(
  username: string
): Promise<V3ChannelView | V3ChannelViewPrivate> {
  const results = await fetch(
    `${process.env.REACT_APP_API_CDN_ENDPOINT}/v3/channel_view/${username}`,
    { credentials: 'include' }
  );

  if (results.ok) {
    const json = await results.json();

    return json;
  } else {
    throw results;
  }
}

export async function apiV3GetPrices(
  id: number
): Promise<V3ChannelSubscriptionPrices> {
  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/channels/${id}/connect/channel_subscription_prices`,
    { credentials: 'include' }
  );

  if (results.ok) {
    const json = await results.json();

    return json;
  } else {
    throw results;
  }
}

export async function apiV3CreateChannelSubscriptionCheckoutSession(
  id: string,
  subdomain: string
): Promise<V3ChannelSubscriptionCheckoutSession> {
  const results = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/v3/channels/${subdomain}/connect/channel_subscription_checkout_sessions`,
    {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        price_id: id
      }),
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json'
      }
    }
  );

  if (results.ok) {
    const json = await results.json();

    return json;
  } else {
    throw results;
  }
}

export const mapApiV3PrivateChannelToAccessCodeDetails = ({
  data: { id, attributes }
}: V3ChannelViewPrivate): AccesscodeDetails => {
  return {
    id: id,
    copy: attributes.livepage_accesscode_copy || '',
    link: attributes.livepage_accesscode_link || '',
    linkType: attributes.livepage_accesscode_link_type || '',
    failed: false,
    username: attributes.username,
    artworkKey: attributes.artwork_original_key,
    logoKey: attributes.logo_original_key,
    themeColor: attributes.theme_color,
    aboutMe: attributes.about_me
  };
};

export const mapApiV3PaidChannelToPaidChannelDetails = ({
  data: { attributes }
}: V3ChannelViewPrivate): PaidChannelDetails => {
  return {
    id: attributes.channel_id,
    copy: attributes.livepage_accesscode_copy || '',
    link: attributes.livepage_accesscode_link || '',
    linkType: attributes.livepage_accesscode_link_type || '',
    failed: false,
    username: attributes.username,
    artworkKey: attributes.artwork_original_key,
    logoKey: attributes.logo_original_key,
    themeColor: attributes.theme_color
  };
};

export const mapApiV3ChannelViewCurrentBroadcast = (
  channel: V3ChannelView
): Broadcast | null => {
  const broadcasts = channel.included.filter(
    (included): included is apiCurrentBroadcast => {
      return included.type === 'broadcast';
    }
  );

  if (!broadcasts[0]) {
    return null;
  }

  const { attributes: broadcast } = broadcasts[0];

  return {
    uid: broadcast.uid.toString(),
    title: broadcast.title,
    duration: broadcast.duration,
    categoryName: broadcast.category_name,
    isLive: broadcast.live,
    url: broadcast.progressive_stream_url,
    heartCount: broadcast.heart_count,
    listenerCount: broadcast.listener_count,
    startedAt: broadcast.started_at,
    eventId: broadcast.event_id?.toString() || null,
    finished: false
  };
};

export const mapApiV3ChannelViewLinks = ({
  data
}: V3ChannelView): ChannelLink[] => {
  return (data.attributes.links || []).map((link): ChannelLink => {
    return {
      name: link.type || '',
      url: link.url || ''
    };
  });
};

export const mapApiV3ChannelViewToState = (
  channel: V3ChannelView
): ChannelDetails => {
  const { data } = channel;

  return {
    ownerId: data.attributes.owner_id.toString(),
    channelId: data.attributes.channel_id
      ? data.attributes.channel_id.toString()
      : null,
    username: data.attributes.username,
    links: mapApiV3ChannelViewLinks(channel),
    listeners: data.attributes.total_unique_listener_count,
    followers: data.attributes.followers_count,
    aboutMe: data.attributes.about_me,
    themeColor: data.attributes.theme_color,
    artworkKey: data.attributes.artwork_original_key,
    logoKey: data.attributes.logo_original_key,
    legacyLivepageUrl: data.attributes.legacy_livepage_url,
    // ternary needed temporarily to avoid disruption during rollout
    // TODO: remove the ternary once the API has been fully rolled out
    featureFlagEnabled:
      data.attributes.channel_view_enabled === undefined
        ? true
        : data.attributes.channel_view_enabled,
    isPrivate: data.attributes.private,
    liveEmbedPlayerEnabled: data.attributes.live_embed_player_enabled,
    recordingEmbedPlayerEnabled: data.attributes.recording_embed_player_enabled,
    gifsInChatEnabled: data.attributes.gifs_in_chat_enabled,
    embedOnly: data.attributes.embed_only,
    removeEmbedBranding: data.attributes.remove_embed_branding
  };
};
