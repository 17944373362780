import React, { FunctionComponent, ReactNode, useContext } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { IconButton } from 'components/Buttons/IconButton';
import { useMediaQuery } from 'react-responsive';
import { UserAgentContext } from 'features/user-agent/userAgent';

interface FullScreenDetailsProps {
  title: string;
  meta?: ReactNode;
  info?: ReactNode;
  onInfoClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const FullScreenDetails: FunctionComponent<FullScreenDetailsProps> = (
  props
) => {
  const isMobile = useMediaQuery({ query: '(max-width: 739px)' });
  const { isNativeAppWebview } = useContext(UserAgentContext);

  return (
    <div className={styles['full-screen__details']}>
      <h1
        className={classNames(
          isNativeAppWebview && isMobile ? 'truncate-multiline' : 'truncate',
          styles['full-screen__details__title']
        )}
      >
        {props.info && props.onInfoClick && !isNativeAppWebview && (
          <IconButton
            label={'Info'}
            onClick={props.onInfoClick}
            icon={'info'}
            noBackground={true}
            size={'small'}
          />
        )}
        {props.title}
      </h1>
      {props.meta && (
        <div className={styles['full-screen__meta']}>{props.meta}</div>
      )}
      {props.info && props.info}
    </div>
  );
};
