import React, {
  FunctionComponent,
  SyntheticEvent,
  useContext,
  useState,
  useRef,
  memo
} from 'react';
import { SearchBar, SearchContextManager } from '@giphy/react-components';
import styles from '../EmojiPicker/styles.module.scss';
import { Icon } from 'components/Icons';
import { IGif } from '@giphy/js-types';
import { useOnClickOutside } from 'usehooks-ts';
import { ThemeContext } from 'features/theme/theme';
import { useAppSelector } from 'hooks/redux';
import { GiphySearchResults } from './SearchResults';
import GiphyAttribution from 'images/PoweredBy_200px-White_HorizText.png';

interface GiphyPickerProps {
  onGifClick: (gif: IGif) => void;
}

export const GiphyPicker: FunctionComponent<GiphyPickerProps> = (props) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const [pickerOpen, togglePicker] = useState<boolean>(false);
  const { darkMode } = useContext(ThemeContext);
  const gifsInChatEnabled = useAppSelector(
    (state) => state.channel.channelDetails?.gifsInChatEnabled
  );

  const handleEmojiClickOutside = () => {
    togglePicker(false);
  };

  useOnClickOutside(pickerRef, handleEmojiClickOutside);

  const onGifClick = (gif: IGif, e: SyntheticEvent<HTMLElement, Event>) => {
    e.preventDefault();
    props.onGifClick(gif);
    togglePicker(false);
  };

  if (!gifsInChatEnabled) {
    return null;
  }

  return (
    <div ref={pickerRef} className="tw-relative">
      <button
        onClick={() => togglePicker(!pickerOpen)}
        className={styles['emoji-picker__button']}
        type="button"
      >
        <Icon icon={'gif'} hidden={true} label={'Choose gif'} />
      </button>
      <SearchContextManager
        apiKey={`${process.env.REACT_APP_GIPHY_API_KEY}`}
        theme={{ darkMode: darkMode, searchbarHeight: 34 }}
        shouldFetchChannels={false}
      >
        {pickerOpen && (
          <div
            className={`tw-bg-background tw-shadow-lg tw-absolute tw-h-[320px] tw-rounded-md tw-w-[280px] tw--right-[32px] tw-bottom-full tw-z-50 tw-overflow-hidden tw-flex tw-flex-col tw-p-4 tw-pb-0`}
          >
            <SearchBar className="tw-border tw-border-solid tw-border-line-color tw-mb-2" />
            <GiphySearchResults onGifClick={onGifClick} />
            <div className="tw-bg-background tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-z-50 tw-border-[0] tw-border-t tw-border-solid tw-border-line-color tw-text-right tw-px-2">
              <img
                src={GiphyAttribution}
                alt="Powered by Giphy"
                className="tw-h-[10px] tw-inline-block"
              />
            </div>
          </div>
        )}
      </SearchContextManager>
    </div>
  );
};

export default memo(GiphyPicker);
