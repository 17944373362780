import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';

export const useArtworkKey = (
  artworkMode: string | undefined,
  key: string | null | undefined
) => {
  const channelArtworkKey =
    useSelector(
      (state: RootState) => state.channel.channelDetails?.artworkKey
    ) || null;
  const [imageKey, setImageKey] = useState<string | null | undefined>();
  const [imageBaseUrl, setImageBaseUrl] = useState<string | undefined>();

  useEffect(() => {
    setImageBaseUrl(process.env.REACT_APP_STORAGE_CDN_URL);
    switch (artworkMode) {
      case 'channel':
        setImageKey(channelArtworkKey);
        break;
      case 'private':
      case 'logo':
      case 'image':
      case 'recording':
        setImageKey(key);
        break;
      default:
        setImageKey(key);
        break;
    }
  }, [setImageKey, setImageBaseUrl, artworkMode, channelArtworkKey, key]);

  return { imageKey, imageBaseUrl };
};
