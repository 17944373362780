import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext
} from 'react';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { useAudio } from 'features/audio/audio';
import { useNavigate, useLocation, matchPath, Link } from 'react-router-dom';
import { autoplayNextEvent } from 'features/events/thunks';
import { setAutoplay } from 'features/events/eventsSlice';
import { AutoplayCountdown } from 'features/events/components/AutoplayCountdown';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { IconButton } from 'components/Buttons/IconButton';
import { nextLiveBroadcastSelector } from 'features/broadcast/selectors';
import { useAppSelector } from 'hooks/redux';
import { useEvent } from '../eventHooks';
import { ThumbImage } from 'components/Images/ThumbImage';

const millsecondsUntilAutoplay = 20000;

interface AutoplayTimerProps {
  enabled: boolean;
}

export const AutoplayTimer: FunctionComponent<AutoplayTimerProps> = (
  props: AutoplayTimerProps
) => {
  const [timerId, setTimerId] = useState<number | null>(null);
  const [timeStarted, setTimeStarted] = useState<DateTime | null>(null);
  const broadcasts = useAppSelector((state) => state);
  const broadcast = nextLiveBroadcastSelector(broadcasts);
  const event = useEvent(broadcast?.eventId || undefined);

  const dispatch = useDispatch();
  const { loadAudio } = useAudio();
  const navigate = useNavigate();
  const { isCreatorAppWebview, isListenerAppWebview, isEmbed } =
    useContext(UserAgentContext);
  const { pathname } = useLocation();
  const isEventPath = matchPath('/events/:id', pathname);

  useEffect(() => {
    if (props.enabled === true && !timerId) {
      setTimerId(
        window.setTimeout(() => {
          dispatch(
            autoplayNextEvent(
              loadAudio,
              navigate,
              isListenerAppWebview,
              isEmbed
            )
          );
        }, millsecondsUntilAutoplay)
      );
      setTimeStarted(DateTime.now());
    }
    if (props.enabled === false && timerId) {
      window.clearTimeout(timerId);
      setTimerId(null);
      setTimeStarted(null);
    }
    return () => {
      if (timerId) {
        window.clearTimeout(timerId);
        setTimerId(null);
        setTimeStarted(null);
      }
    };
  }, [
    props.enabled,
    dispatch,
    loadAudio,
    navigate,
    timerId,
    isEmbed,
    isListenerAppWebview
  ]);

  if (props.enabled === false || !timeStarted) {
    return null;
  }

  if (isListenerAppWebview && !isEventPath) {
    return null;
  }

  if (isCreatorAppWebview) {
    return null;
  }

  if (!broadcast || !event) {
    return null;
  }

  const navigateAutoPlay = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(
      autoplayNextEvent(loadAudio, navigate, isListenerAppWebview, isEmbed)
    );
    if (timerId) {
      window.clearTimeout(timerId);
    }
  };

  const cancelAutoplay = () => {
    dispatch(setAutoplay(false));
  };

  return (
    <Link
      to={`/events/${event.id}`}
      onClick={navigateAutoPlay}
      state={{
        animate: false,
        autoPlay: true,
        hideLoader: true
      }}
      className="tw-max-w-[95%] sm:tw-max-w-lg tw-w-full tw-p-4 tw-bg-black tw-bg-opacity-80 tw-flex tw-gap-4 tw-font-bold tw-text-base tw-text-white hover:tw-text-white tw-rounded-2xl tw-top-[68px] md:tw-top-4 tw-fixed tw-left-1/2 tw--translate-x-1/2 tw-z-[1000] [.ios-listener_&]:tw-top-[calc(80px+var(--sat))] [.android-listener_&]:tw-top-[calc(80px+var(--sat))]"
    >
      <div className="tw-rounded-md tw-overflow-hidden tw-h-20 tw-w-20 tw-relative [&>img]:tw-h-full [&>img]:tw-w-full [&>img]:tw-object-cover">
        <ThumbImage
          artworkKey={event.artworkKey}
          artworkMode={event.artworkMode}
        />
        <div className="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-bg-black tw-bg-opacity-40 tw-z-[10]"></div>
        <div className="tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2 tw-z-[20]">
          <AutoplayCountdown
            startedAt={timeStarted}
            millisecondsUntilAutoplay={millsecondsUntilAutoplay}
          />
        </div>
      </div>
      <div className="tw-flex-1">
        <div className="tw-flex tw-gap-2 tw-text-red-50 tw-uppercase tw-items-center">
          Autoplaying
        </div>
        <p className="tw-text-xl tw-font-medium tw-line-clamp-2 tw-m-0">
          {event.title}
        </p>
      </div>
      <div className="tw-self-center">
        <IconButton
          showLabel={false}
          icon={'x'}
          label={'Cancel auto play'}
          onClick={cancelAutoplay}
          iconSize={12}
          size={'small'}
        />
      </div>
    </Link>
  );
};
