import React, { FunctionComponent, useContext } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { IconButton } from '../Buttons/IconButton';
import { UserAgentContext } from 'features/user-agent/userAgent';

export const VisualizerToggle: FunctionComponent = () => {
  const { isNativeAppWebview, isEmbed } = useContext(UserAgentContext);
  const [visualizerVisible, setVisualizerVisible] = useLocalStorage(
    'visualizer-visible',
    true
  );

  const toggleVisualizer = () => {
    setVisualizerVisible((prevValue: boolean) => !prevValue);
  };

  if (isNativeAppWebview || isEmbed) {
    return null;
  }

  return (
    <IconButton
      label={visualizerVisible ? 'Hide visualizer' : 'Show visualizer'}
      onClick={toggleVisualizer}
      icon={visualizerVisible ? 'waveformOff' : 'waveformOn'}
    />
  );
};
