import React, { FunctionComponent, ReactNode } from 'react';
import { FullScreenWrapper } from 'components/Layouts/FullScreenView/FullScreenWrapper';
import { EventModel } from 'features/events/EventModel';
import { useIsEventLive } from 'features/events/eventHooks';
import { useAppSelector } from 'hooks/redux';
import { currentBroadcastFinishedSelector } from 'features/broadcast/selectors';
import { UnlistedBanner } from '../../components/UnlistedBanner';

interface EventWrapperProps {
  children: ReactNode;
  event: EventModel | null;
}

export const EventWrapper: FunctionComponent<EventWrapperProps> = ({
  children,
  event
}) => {
  const isLive = useIsEventLive(event);
  const chatVisible = useAppSelector((state) => state.chat.isVisible);
  const currentBroadcastFinished = useAppSelector(
    currentBroadcastFinishedSelector
  );
  const eventChatEnabled =
    event?.chatEnabled && chatVisible && (isLive || currentBroadcastFinished);

  if (!event) {
    return null;
  }

  return (
    <FullScreenWrapper
      artworkKey={event.artworkKey}
      artworkMode={event.artworkMode}
      updatedTimestamp={event.lastUpdatedAt}
      type={'event'}
      chatVisible={eventChatEnabled}
    >
      <UnlistedBanner />
      {children}
    </FullScreenWrapper>
  );
};
