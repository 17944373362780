import React, { FunctionComponent, ReactNode } from 'react';
import { ModalHeader } from 'components/Modal/Header';
import Modal from 'react-modal';
import styles from './styles.module.scss';
import { ReactComponent as MixlrLogo } from 'images/mixlr-logo.svg';
import classNames from 'classnames';

interface ModalWrapperProps {
  title: string;
  showChannelLogo?: boolean;
  showMixlrText?: boolean;
  isOpen: boolean;
  children: ReactNode;
  size?: 'wide';
  onRequestClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ModalWrapper: FunctionComponent<ModalWrapperProps> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      className={classNames(styles['modal'], {
        [styles['modal--wide']]: props.size === 'wide'
      })}
      overlayClassName={styles['modal__overlay']}
      ariaHideApp={false}
      contentLabel={props.title}
      onRequestClose={props.onRequestClose}
    >
      <ModalHeader
        title={props.title}
        showChannelLogo={props.showChannelLogo}
        onClick={props.onRequestClose}
      />
      <div className={styles['modal__content']}>{props.children}</div>
      {props.showMixlrText && (
        <div className={styles['modal__footer']}>
          <a
            href={process.env.REACT_APP_WEBSITE_ENDPOINT}
            target="_blank"
            rel="noopener noreferrer"
          >
            Powered by <MixlrLogo />
          </a>
        </div>
      )}
    </Modal>
  );
};
