import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { Card } from 'components/Card';
import { useSelector } from 'react-redux';
import { Loader } from 'components/Loader';
import { useSubdomain } from 'hooks/useSubdomain';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import {
  allPublicAndUnlistedCollectionRecordingsSelector,
  collectionRecordingsPageCount
} from 'features/collections/collectionsSelectors';
import { EmptyCollectionRecordings } from './EmptyCollectionRecordings';
import { fetchCollectionRecordings } from 'features/collections/collectionsSlice';
import { useCurrentCollection } from 'features/collections/collectionHooks';
import { useOnceEffect } from 'hooks/useOnceEffect';
import { useAppSelector } from 'hooks/redux';

export interface InfiniteScrollCollectionRecordingsProps {
  itemsPerPage: number;
}

export const InfiniteScrollCollectionRecordings: FunctionComponent<
  InfiniteScrollCollectionRecordingsProps
> = ({ itemsPerPage }) => {
  const [currentItems, setCurrentItems] = useState<RecordingModel[]>([]);
  const [itemOffset, setItemOffset] = useState(0);
  const currentPageRef = useRef(1);
  const loading = useAppSelector(
    (state) => state.collections.collectionRecordingsLoading
  );
  const subdomain = useSubdomain();
  const dispatch = useDispatch();
  const { ref, inView } = useInView({
    threshold: 0
  });
  const collection = useCurrentCollection();
  const recordings: RecordingModel[] = useSelector(
    allPublicAndUnlistedCollectionRecordingsSelector
  );
  const recordingsPageCount = useSelector(collectionRecordingsPageCount);

  useOnceEffect(function fetchInitialCollectionRecordings() {
    if (!collection) return;
    console.log('Fetching initial recordings for: ', collection.id);
    dispatch(fetchCollectionRecordings(subdomain, collection.id, 1));
  }, collection);

  useEffect(
    function setCurrentRecordings() {
      const endOffset = itemOffset + itemsPerPage;
      console.log(
        `Loading collection recordings from ${itemOffset} to ${endOffset}`
      );
      setCurrentItems(recordings);
    },
    [itemOffset, itemsPerPage, recordings]
  );

  useEffect(
    function fetchCollectionRecordingsOnScroll() {
      if (!collection) return;
      if (inView && currentPageRef.current <= recordingsPageCount) {
        dispatch(
          fetchCollectionRecordings(
            subdomain,
            collection.id,
            currentPageRef.current + 1
          )
        );
        const newOffset = currentPageRef.current * itemsPerPage;
        setItemOffset(newOffset);
        currentPageRef.current += 1;
      }
    },
    [inView, dispatch, itemsPerPage, recordingsPageCount, subdomain, collection]
  );

  if (recordings.length === 0 && !loading) {
    return <EmptyCollectionRecordings />;
  }

  return (
    <div className="container--wide">
      <ul className="list--reset grid">
        {currentItems.map((recording, index) => {
          const lastElement = index === currentItems.length - 1;
          return (
            <li
              className="col-xss-12 col-xs-6"
              key={recording.id}
              ref={lastElement ? ref : null}
            >
              <Card
                id={recording.id}
                url={`/recordings/${recording.id}`}
                title={`${recording.title}`}
                artworkKey={recording.artworkKey}
                artworkMode={'recording'}
                type={'recording'}
              />
            </li>
          );
        })}
      </ul>
      {loading && inView && (
        <div className="text--center mar-t mar-b">
          <Loader />
        </div>
      )}
    </div>
  );
};
