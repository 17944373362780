import React, { FunctionComponent, ReactNode } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useCurrentEvent, useIsEventLive } from 'features/events/eventHooks';

interface FullScreenControlsProps {
  children: ReactNode[];
}

export const FullScreenControls: FunctionComponent<FullScreenControlsProps> = ({
  children
}) => {
  const event = useCurrentEvent();
  const isLive = useIsEventLive(event);

  if (!children) {
    return null;
  }

  return (
    <div
      className={classNames(styles['full-screen__controls'], {
        [styles['full-screen__controls--active']]: isLive
      })}
    >
      <ul className={styles['full-screen__controls__list']}>
        {children.map((child: ReactNode, i) => (
          <li key={i}>{child}</li>
        ))}
      </ul>
    </div>
  );
};
