import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect
} from 'react';
import { Menu } from '../ChannelRecordings/Menu';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useSubdomain } from 'hooks/useSubdomain';
import { useAppSelector } from 'hooks/redux';
import { useMediaQuery } from 'react-responsive';
import ReactPaginate from 'react-paginate';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCollections } from 'features/collections/collectionsSlice';
import { CollectionModel } from 'features/collections/CollectionModel';
import { allPublicCollectionsSelector } from 'features/collections/collectionsSelectors';
import { EmptyRecordings } from '../ChannelRecordings/EmptyRecordings';
import paginationStyles from 'components/Pagination/styles.module.scss';
import { Loader } from 'components/Loader';
import { CollectionListItem } from 'features/collections/Components/CollectionListItem';

export interface PaginatedCollectionsProps {
  itemsPerPage: number;
}

export const PaginatedCollections: FunctionComponent<
  PaginatedCollectionsProps
> = ({ itemsPerPage }) => {
  const [currentItems, setCurrentItems] = useState<CollectionModel[]>([]);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const loading = useAppSelector(
    (state) => state.collections.collectionLoading
  );
  const paginationStartIds = useAppSelector(
    (state) => state.collections.paginationStartIds
  );
  const pageCount = useAppSelector((state) => state.collections.pageCount);
  const subdomain = useSubdomain();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPage: number = +(useParams().page || 1);
  const channelName = useAppSelector(
    (state) => state.channel.channelDetails?.username
  );

  const collections: CollectionModel[] = useAppSelector(
    allPublicCollectionsSelector
  );

  const onPageChange = (event: { selected: number }) => {
    const scrollToOptions = { top: 0, left: 0, behavior: 'instant' };
    // https://github.com/microsoft/TypeScript/issues/47441#issuecomment-1516836705
    window.scrollTo(scrollToOptions as unknown as ScrollToOptions);
    navigate(`../collections/page/${event.selected + 1}`);
  };

  const getRecordingSet = useCallback(
    (paginationStartId: number) => {
      const startOffset = collections
        .map((r) => r.id)
        .indexOf(paginationStartId.toString());
      const endOffset = startOffset + itemsPerPage;
      setCurrentItems(collections.slice(startOffset, endOffset));
    },
    [itemsPerPage, collections]
  );

  useEffect(
    function fetchRecordingsOnLoadOrPageChange() {
      const paginationStartId = paginationStartIds.find(
        (p) => p.pageNumber === currentPage
      );
      if (paginationStartId && collections) {
        getRecordingSet(paginationStartId.recordingStartId);
      } else {
        dispatch(fetchCollections(subdomain, currentPage, navigate));
      }
    },
    [
      currentPage,
      dispatch,
      subdomain,
      paginationStartIds,
      collections,
      getRecordingSet,
      navigate
    ]
  );

  const setInitialPage = currentPage - 1;

  if (collections.length === 0 && !loading) {
    return (
      <>
        <Menu />
        <EmptyRecordings username={channelName || ''} />
      </>
    );
  }

  return (
    <div className="container--wide">
      <Menu />
      <h1 className="tw-sr-only">Collections</h1>
      {currentItems.length > 0 && (
        <ul className="list--reset">
          {currentItems.map((collection) => (
            <li key={collection.id} className="tw-mb-8">
              <CollectionListItem collection={collection} />
            </li>
          ))}
        </ul>
      )}
      {pageCount > 1 && (
        <ReactPaginate
          containerClassName={classNames(paginationStyles['pagination'], {
            [paginationStyles['pagination--loading']]: loading
          })}
          pageClassName={paginationStyles['pagination__page']}
          pageLinkClassName={paginationStyles['pagination__link']}
          activeLinkClassName={paginationStyles['pagination__link--active']}
          previousLinkClassName={paginationStyles['pagination__link--prev']}
          nextLinkClassName={paginationStyles['pagination__link--next']}
          disabledLinkClassName={paginationStyles['pagination__link--disabled']}
          breakLabel="..."
          nextLabel="next ›"
          forcePage={setInitialPage}
          onPageChange={onPageChange}
          marginPagesDisplayed={1}
          pageRangeDisplayed={isMobile ? 2 : 5}
          pageCount={pageCount}
          previousLabel="‹ prev"
          renderOnZeroPageCount={() => null}
        />
      )}
      {loading && <Loader fillComponent={true} />}
    </div>
  );
};
