import React, { FunctionComponent } from 'react';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { FullScreenControls } from 'components/Layouts/FullScreenView/FullScreenControls';
import { FullScreenDetails } from 'components/Layouts/FullScreenView/FullScreenDetails';
import { FullScreenFooter } from 'components/Layouts/FullScreenView/FullScreenFooter';
import { Share } from 'components/Share';
import { VolumeControl } from 'components/Player/VolumeControl';
import { RecordingMeta } from 'features/recordings/components/RecordingMeta';
import { RecordingInfo } from 'features/recordings/components/RecordingInfo';
import { useDispatch } from 'react-redux';
import { setInfoVisible } from 'features/events/eventsSlice';
import { VisualizerToggle } from 'components/Player/VisualizerToggle';

interface RecordingFooterProps {
  recording: RecordingModel | null;
}

export const RecordingFooter: FunctionComponent<RecordingFooterProps> = ({
  recording
}) => {
  const dispatch = useDispatch();

  if (!recording) {
    return null;
  }

  return (
    <FullScreenFooter
      leftColumn={
        <>
          <VolumeControl />
          <FullScreenDetails
            title={recording.title}
            meta={<RecordingMeta />}
            info={<RecordingInfo recording={recording} />}
            onInfoClick={() => dispatch(setInfoVisible(true))}
          />
        </>
      }
      rightColumn={
        <FullScreenControls>
          <></>
          <VisualizerToggle />
          <Share title={recording.title} label={'recording'} />
        </FullScreenControls>
      }
    />
  );
};
