import React, { FunctionComponent } from 'react';
import { cloudflareUrl } from 'utilities/cloudflareImageResizer';
import { ReactComponent as EventBackground } from 'images/event-background.svg';
import { ReactComponent as RecordingBackground } from 'images/recording-background.svg';
import { useArtworkKey } from 'components/Images/imageHooks';

interface CardImageProps {
  artworkKey: string | null | undefined;
  artworkMode?: string | undefined;
  updatedTimestamp?: string;
  className?: string;
  type: 'event' | 'recording';
}

export const CardImage: FunctionComponent<CardImageProps> = ({
  artworkKey,
  artworkMode,
  updatedTimestamp,
  className,
  type
}) => {
  const { imageKey, imageBaseUrl } = useArtworkKey(artworkMode, artworkKey);

  let key = imageKey;

  if (updatedTimestamp) {
    key = `${key}?ts=${updatedTimestamp}`;
  }

  const generateImageUrl = (imageDpr: number): string | undefined => {
    return (
      cloudflareUrl(key, {
        height: 600,
        width: 800,
        quality: 80,
        dpr: imageDpr,
        baseUrl: imageBaseUrl
      }) || undefined
    );
  };

  if (!imageKey || artworkMode === 'theme') {
    return type === 'recording' ? <RecordingBackground /> : <EventBackground />;
  }

  return (
    <img
      srcSet={`${generateImageUrl(1)} 1x, 
         ${generateImageUrl(2)} 2x`}
      src={generateImageUrl(1)}
      alt=""
      loading="lazy"
      className={className}
    />
  );
};
