import React, { FunctionComponent } from 'react';
import { SeekBar } from 'components/Player/SeekBar';
import { RewindButton } from 'components/Player/RewindButton';
import { ForwardButton } from 'components/Player/ForwardButton';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useAudio } from 'features/audio/audio';

export const PlayerControls: FunctionComponent = () => {
  const loading = useSelector((state: RootState) => state.audio.loading);
  const { audio } = useAudio();

  return (
    <div
      className={classNames(styles['player__controls'], {
        [styles['player__controls--loading']]: loading || !audio
      })}
    >
      <RewindButton />
      <SeekBar />
      <ForwardButton />
    </div>
  );
};
