import React, { FunctionComponent, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useDispatch } from 'react-redux';
import { setMiniPlayerVisible } from 'features/channel/channelSlice';
import { unloadEvent, unloadRecording } from 'thunks/navigationThunks';
import { IconButton } from '../Buttons/IconButton';
import { VolumeControl } from './VolumeControl';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { UserImage } from '../UserImage';
import { useAudio } from 'features/audio/audio';
import { motion, AnimatePresence } from 'framer-motion';
import { Visualizer } from 'components/Player/Visualizer';
import { useReducedMotion } from 'framer-motion';
import { isAnimatingLayout } from 'features/ui/uiSlice';
import { isMobileOnly } from 'react-device-detect';
import { ThumbImage } from 'components/Images/ThumbImage';
import { useLocalStorage } from 'usehooks-ts';
import { fetchAudioAbort } from 'features/audio/audioThunks';
import { setEventMinimised } from 'features/events/eventsSlice';

interface MiniPlayerProps {
  title: string;
  url: string;
  artworkKey?: string;
  artworkMode: string;
  meta?: ReactNode;
  showVolumeControl: boolean;
}

export const MiniPlayer: FunctionComponent<MiniPlayerProps> = (props) => {
  const dispatch = useDispatch();
  const { stop } = useAudio();
  const shouldReduceMotion = useReducedMotion();

  const miniPlayerVisible = useSelector(
    (state: RootState) => state.channel.miniPlayerVisible
  );

  const [visualizerVisible] = useLocalStorage('visualizer-visible', true);

  const onExpand = () => {
    dispatch(setMiniPlayerVisible(false));
    dispatch(setEventMinimised(false));
  };

  const onClose = () => {
    fetchAudioAbort();
    stop(true);
    dispatch(unloadEvent());
    dispatch(unloadRecording());
  };

  const onAnimationStart = () => {
    dispatch(isAnimatingLayout(true));
  };

  const onAnimationComplete = () => {
    dispatch(isAnimatingLayout(false));
  };

  const linkState = {
    prevPath: location.pathname,
    animate: true,
    hideLoader: true
  };

  return (
    <AnimatePresence>
      {miniPlayerVisible && (
        <motion.div
          className={classNames(styles['player-mini'], {})}
          initial={{ y: '100%' }}
          animate={{ y: 0 }}
          exit={{ y: '100%' }}
          transition={{ duration: shouldReduceMotion ? 0 : 0.3 }}
          onAnimationStart={onAnimationStart}
          onAnimationComplete={onAnimationComplete}
        >
          <Link
            to={props.url}
            onClick={onExpand}
            className={styles['player-mini__image']}
            state={linkState}
          >
            <UserImage size={'small'}>
              <ThumbImage
                artworkKey={props.artworkKey}
                artworkMode={props.artworkMode}
              />
            </UserImage>
            {visualizerVisible && <Visualizer isMini={true} />}
          </Link>
          {!isMobileOnly && props.showVolumeControl && (
            <VolumeControl cssClass={'volume__button--invert'} />
          )}
          <Link
            to={props.url}
            onClick={onExpand}
            className={classNames(styles['player-mini__details'])}
            state={linkState}
          >
            <div>
              <h2
                className={classNames(styles['player-mini__title'], 'truncate')}
              >
                {props.title}
              </h2>
              {props.meta && (
                <div className={styles['player-mini__meta']}>{props.meta}</div>
              )}
            </div>
          </Link>
          <div className={styles['player__actions']} role="toolbar">
            <IconButton
              icon={'x'}
              label={'Close mini player'}
              showLabel={false}
              onClick={onClose}
              cssClass={'button--icon--invert'}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
