import React, { FunctionComponent, useContext } from 'react';
import { RootState } from 'rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setInfoVisible } from 'features/events/eventsSlice';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { DateTime } from 'luxon';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { formatTime } from 'utilities/formatters';
import { ModalWrapper } from 'components/Modal/Wrapper';
import Linkify from 'linkify-react';

interface RecordingInfoProps {
  recording: RecordingModel | null;
}

export const RecordingInfo: FunctionComponent<RecordingInfoProps> = ({
  recording
}) => {
  const dispatch = useDispatch();
  const showInfo = useSelector((state: RootState) => state.events.showInfo);
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const { isNativeAppWebview } = useContext(UserAgentContext);

  const linkifyOptions = {
    target: '_blank',
    rel: 'noopener',
    format: {
      url: (value: string) =>
        value.length > 50 ? value.slice(0, 50) + '…' : value
    },
    className: 'comment__url'
  };

  const closeModal = () => {
    dispatch(setInfoVisible(false));
  };

  if (!recording || !channel || isNativeAppWebview) {
    return null;
  }

  return (
    <ModalWrapper
      isOpen={showInfo}
      title={'Recording details'}
      onRequestClose={closeModal}
      size={'wide'}
    >
      <h3>{recording.title}</h3>
      <div className="grid">
        {recording.createdAt && (
          <div className="col-xss-12 col-md-4">
            <dl>
              <dt>Created on:</dt>
              <dd>
                {DateTime.fromISO(recording.createdAt).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </dd>
            </dl>
          </div>
        )}
        {recording.duration && (
          <div className="col-xss-12 col-md-4">
            <dl>
              <dt>Duration</dt>
              <dd>{formatTime(recording.duration)}</dd>
            </dl>
          </div>
        )}
      </div>
      {recording.description && (
        <p className="textarea-format">
          <Linkify options={linkifyOptions}>{recording.description}</Linkify>
        </p>
      )}
    </ModalWrapper>
  );
};
