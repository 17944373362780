import React, { FunctionComponent } from 'react';
import { EventModel } from 'features/events/EventModel';
import { Card } from 'components/Card';

export interface InfiniteScrollEventsProps {
  events: EventModel[];
}

export const InfiniteScrollEvents: FunctionComponent<
  InfiniteScrollEventsProps
> = ({ events }) => {
  return (
    <div className="container--wide">
      <h1>Events</h1>
      <ul className="list--reset grid">
        {events.map((event) => {
          return (
            <li className="col-xss-12 col-xs-6" key={event.id}>
              <Card
                id={event.id}
                url={`/events/${event.id}`}
                title={`${event.title}`}
                artworkKey={event.artworkKey}
                artworkMode={event.artworkMode}
                isPrivate={event.isPrivate()}
                updatedTimestamp={event.lastUpdatedAt}
                type={'event'}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
