import React, { FunctionComponent } from 'react';
import { Icon } from '../Icons';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface LiveNowProps {
  isLive: boolean | undefined;
  noBackground?: boolean;
}

export const LiveNow: FunctionComponent<LiveNowProps> = (props) => {
  if (!props.isLive) {
    return null;
  }

  return (
    <div
      className={classNames(styles['live-now'], {
        [styles['live-now--text']]: props.noBackground
      })}
    >
      <Icon
        icon={'liveNow'}
        height={16}
        hidden={true}
        label={'Live'}
        width={16}
      />
      Live
    </div>
  );
};
