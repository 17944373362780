import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { recentMessagesSelector } from 'features/chat/chatSelectors';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { UserAgentContext } from 'features/user-agent/userAgent';

export const useRecentMessages = () => {
  return useSelector((state: RootState) => recentMessagesSelector(state));
};

export const useSlidePanelForCrowd = () => {
  const { isDACreatorApp } = useContext(UserAgentContext);
  const { width } = useWindowDimensions();
  return isDACreatorApp || width >= 740;
};
