import React, { FunctionComponent } from 'react';
import { FullScreenContent } from 'components/Layouts/FullScreenView/FullScreenContent';
import { ConnectingAudio } from 'components/ConnectingAudio';
import { EventModel } from 'features/events/EventModel';
import { EventCreatorCountdown } from 'features/events/EventCreator/EventCreatorCountdown';
import { useIsEventLive } from 'features/events/eventHooks';
import { EventChat } from 'features/events//EventChat';
import { useAppSelector } from 'hooks/redux';
import { currentBroadcastFinishedSelector } from 'features/broadcast/selectors';

interface EventWrapperProps {
  event: EventModel | null;
}

export const EventCreatorContent: FunctionComponent<EventWrapperProps> = ({
  event
}) => {
  const isLive = useIsEventLive(event);
  const nativeEventStarted = useAppSelector(
    (state) => state.events.nativeEventStarted
  );
  const nativeEventStopped = useAppSelector(
    (state) => state.events.nativeEventStopped
  );
  const currentBroadcastFinished = useAppSelector(
    currentBroadcastFinishedSelector
  );

  if (!event) {
    return null;
  }

  const centerColumn = nativeEventStarted ? (
    <ConnectingAudio />
  ) : isLive ? null : (
    <EventCreatorCountdown />
  );

  const rightColumn =
    isLive || currentBroadcastFinished || nativeEventStopped ? (
      <EventChat event={event} />
    ) : null;

  return (
    <FullScreenContent centerColumn={centerColumn} rightColumn={rightColumn} />
  );
};
