import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';

interface FullScreenContentProps {
  centerColumn: React.ReactNode;
  rightColumn?: React.ReactNode | null;
  visualizer?: React.ReactNode | null;
}

export const FullScreenContent: FunctionComponent<FullScreenContentProps> = (
  props
) => {
  return (
    <div className={styles['full-screen__content']}>
      {props.visualizer && props.visualizer}
      <div className={styles['full-screen__content__center-col']}>
        {props.centerColumn}
      </div>
      {props.rightColumn && props.rightColumn}
    </div>
  );
};
