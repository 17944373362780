import React, { FunctionComponent, useContext } from 'react';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { ThemeContext } from 'features/theme/theme';
import styles from './styles.module.scss';

export const DarkModeSwitch: FunctionComponent = () => {
  const { isNativeAppWebview, isEmbed } = useContext(UserAgentContext);
  const { darkMode, setDarkMode } = useContext(ThemeContext);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  if (isNativeAppWebview || isEmbed) {
    return null;
  }

  return (
    <div className={styles['dark-mode-switch']}>
      <ToggleSwitch
        checked={darkMode}
        onChange={toggleDarkMode}
        onIcon={'moon'}
        offIcon={'sun'}
        cssClass={'toggle-switch--dark'}
      />
    </div>
  );
};
