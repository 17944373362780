import { IconButton } from 'components/Buttons/IconButton';
import { ButtonLink } from 'components/Buttons/ButtonLink';
import { ExternalIconLink } from 'components/Buttons/ExternalIconLink';
import {
  followUser,
  unfollowUser
} from 'features/current-user/currentUserSlice';
import React, { FunctionComponent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentUser } from 'features/current-user/hooks';
import { showAuthenticationEmailPromptForm } from 'features/current-user/currentUserSlice';
import { RootState } from 'rootReducer';
import { UserAgentContext } from 'features/user-agent/userAgent';

export interface FollowButtonsProps {
  selectedProfileId: string;
  followedIds: string[];
  showAslink?: boolean;
}

export const FollowButtons: FunctionComponent<FollowButtonsProps> = ({
  followedIds,
  selectedProfileId,
  showAslink
}: FollowButtonsProps) => {
  const isFollowing: boolean = followedIds.includes(selectedProfileId);
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const { isListenerAppWebview, isEmbed } = useContext(UserAgentContext);

  const onClick = () => {
    if (currentUser) {
      dispatch(
        isFollowing
          ? unfollowUser(selectedProfileId)
          : followUser(selectedProfileId)
      );
    } else {
      if (isListenerAppWebview) {
        window.location.href = `mixlr://auth?url=${process.env.REACT_APP_WEBSITE_ENDPOINT}/signin`;
      } else {
        dispatch(
          showAuthenticationEmailPromptForm(
            `To follow ${channel && channel.username}`
          )
        );
      }
    }
  };

  if (isEmbed) {
    return (
      <ExternalIconLink
        showLabel={true}
        icon={'userAdd'}
        label={'Follow'}
        href={window.location.origin}
        target="_blank"
        rel="noopener noreferrer"
        iconSize={14}
      />
    );
  }

  if (showAslink) {
    return (
      <ButtonLink
        label={isFollowing ? 'Following' : 'Follow'}
        icon={isFollowing ? 'userTick' : 'userAdd'}
        onClick={onClick}
        iconSize={14}
      />
    );
  }

  return (
    <IconButton
      label={isFollowing ? 'Following' : 'Follow'}
      onClick={onClick}
      showLabel={true}
      size={'mini'}
      icon={isFollowing ? 'userTick' : 'userAdd'}
      isHighlighted={isFollowing}
    />
  );
};
