import { sendBroadcastHeart } from 'features/broadcast/thunks';
import { useIsBroadcaster, useCurrentUser } from 'features/current-user/hooks';
import React, { FunctionComponent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import styles from './styles.module.scss';
import { HeartCount } from 'components/Hearts/HeartCount';
import { useCurrentEvent } from 'features/events/eventHooks';
import { showAuthenticationEmailPromptForm } from 'features/current-user/currentUserSlice';
import { useAppSelector } from 'hooks/redux';
import { currentEventBroadcastSelector } from 'features/broadcast/selectors';
import { IconButton } from 'components/Buttons/IconButton';
import { Icon } from '../Icons';
import classNames from 'classnames';
import { UserAgentContext } from 'features/user-agent/userAgent';

export const HeartButton: FunctionComponent = () => {
  const currentBroadcast = useAppSelector(currentEventBroadcastSelector);
  const currentBroadcastUID = currentBroadcast?.uid;

  const isHeartLoading = useSelector(
    (state: RootState) => state.broadcasts.heartLoading
  );
  const currentUser = useCurrentUser();
  const isBroadcaster = useIsBroadcaster();
  const dispatch = useDispatch();
  const event = useCurrentEvent();
  const { isListenerAppWebview, isEmbed } = useContext(UserAgentContext);

  const heartingButtonDisabled = !currentBroadcastUID || isBroadcaster;

  const heartBroadcast = () => {
    if (!currentBroadcastUID) {
      return;
    }
    if (isEmbed) {
      window.open(window.location.href, '_blank');
      return;
    }
    if (currentUser) {
      dispatch(sendBroadcastHeart(currentBroadcastUID));
    } else {
      if (isListenerAppWebview) {
        window.location.href = `mixlr://auth?url=${process.env.REACT_APP_WEBSITE_ENDPOINT}/signin`;
      } else {
        dispatch(showAuthenticationEmailPromptForm(`To send a ❤️`));
      }
    }
  };

  if (event && !event.heartingEnabled) {
    return null;
  }

  const renderIconOrButton = () => {
    if (heartingButtonDisabled) {
      return <Icon hidden={true} icon={'heart'} label={''} />;
    }
    return (
      <IconButton
        label={'Heart this event'}
        onClick={heartBroadcast}
        icon={'heart'}
        isDisabled={isHeartLoading}
        isLoading={isHeartLoading}
      />
    );
  };

  return (
    <div
      className={classNames(styles['heart-button'], {
        [styles['heart-button--disabled']]: heartingButtonDisabled
      })}
    >
      {renderIconOrButton()}
      <HeartCount />
    </div>
  );
};
