import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext
} from 'react';
import { useParams } from 'react-router-dom';
import { useEvent } from 'features/events/eventHooks';
import styles from './styles.module.scss';
import { Share } from 'components/Share';
import { EventDate } from 'features/events/EventDate';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { EventInfo } from 'features/events/EventInfo';
import { EventCountdownTimer } from 'features/events/components/EventCountdownTimer';
import { ButtonLink } from 'components/Buttons/ButtonLink';
import { setInfoVisible } from 'features/events/eventsSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/redux';

export const EventCountdown: FunctionComponent = () => {
  const { id } = useParams();
  const event = useEvent(id);
  const { isEmbed } = useContext(UserAgentContext);
  const dispatch = useDispatch();
  const removeEmbedBranding: boolean = useAppSelector(
    (state) => state.channel?.channelDetails?.removeEmbedBranding || false
  );

  const removeBranding: boolean = isEmbed && removeEmbedBranding;

  const calculateTimeLeft = (): string | undefined => {
    return event?.durationToEvent()?.toFormat('hh:mm:ss');
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isOverdue, setIsOverdue] = useState(event?.isOverdue());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      setIsOverdue(event?.isOverdue());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const Overdue = () => {
    if (event?.endedAt) {
      return (
        <p className="tw-mb-2">
          This live event has ended, stay tuned for the next!
        </p>
      );
    } else {
      return <p className="tw-mb-2">This event has not started yet.</p>;
    }
  };

  if (!event) {
    return null;
  }

  if (event.isAdhoc()) {
    return (
      <div className={styles['countdown']}>
        <Overdue />
      </div>
    );
  }

  return (
    <div className={styles['countdown']}>
      <h2 className="tw-m-2 tw-line-clamp-2 max-w-prose">{event.title}</h2>
      <div className={styles['countdown__date']}>
        <EventDate eventId={event.id} hideTime={event.isIn24hrs()} />
      </div>
      {!isEmbed && (
        <ButtonLink
          label={'View event details'}
          icon={'info'}
          iconSize={16}
          onClick={() => dispatch(setInfoVisible(true))}
        />
      )}
      {isOverdue || event.startedAt ? (
        <Overdue />
      ) : (
        <EventCountdownTimer event={event} timeLeft={timeLeft} />
      )}
      {!removeBranding && (
        <Share title={event.title} label={'event'} showLabel={true} />
      )}
      <EventInfo event={event} />
    </div>
  );
};
