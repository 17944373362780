import React, { FunctionComponent, ReactNode, useContext } from 'react';
import styles from './styles.module.scss';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { IconLink } from 'components/Buttons/IconLink';
import { LogoImage } from 'components/LogoImage';
import { useDispatch } from 'react-redux';
import { setMiniPlayerVisible } from 'features/channel/channelSlice';
import { ThumbImage } from 'components/Images/ThumbImage';
import { SupportButton } from 'components/Support/SupportButton';
import classNames from 'classnames';
import { FollowButtons } from 'features/chat/components/FollowButtons';
import { useCurrentUser } from 'features/current-user/hooks';
import { useMediaQuery } from 'react-responsive';
import { DarkModeSwitch } from 'components/DarkModeSwitch';
import { PrivacyNotice } from 'features/privacy/privacyNotice';
import { setEventMinimised } from 'features/events/eventsSlice';

interface FullScreenHeaderProps {
  statusPill?: ReactNode;
  minimiseUrl?: string;
}

export const FullScreenHeader: FunctionComponent<FullScreenHeaderProps> = (
  props
) => {
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const { isCreatorAppWebview, isListenerAppWebview, isEmbed } =
    useContext(UserAgentContext);
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const isMobile = useMediaQuery({ query: '(max-width: 739px)' });

  const onMinimise = () => {
    dispatch(setMiniPlayerVisible(true));
    dispatch(setEventMinimised(true));
  };

  if (!channel || isCreatorAppWebview) {
    return null;
  }

  const removeBranding: boolean = isEmbed && channel.removeEmbedBranding;

  const renderLinks = () => {
    if (isListenerAppWebview) {
      return null;
    } else {
      return (
        <div className={styles['full-screen__header__links']}>
          {props.statusPill && props.statusPill}
          <DarkModeSwitch />
          {props.minimiseUrl && (
            <IconLink
              showLabel={false}
              icon={'chevronDown'}
              label={'Minimize event'}
              href={props.minimiseUrl || '/'}
              onClick={onMinimise}
              isWhite={true}
              iconSize={20}
              state={{ hideLoader: true }}
            />
          )}
        </div>
      );
    }
  };

  const logoImageSize = (): string => {
    if (isEmbed) {
      return 'embed';
    } else if (isMobile) {
      return 'small';
    }
    return 'medium';
  };

  return (
    <header
      className={classNames(styles['full-screen__header'], {
        [styles['full-screen__header--native']]: isListenerAppWebview
      })}
    >
      <div className={styles['full-screen__logo']}>
        <LogoImage
          size={logoImageSize()}
          hasFallback={!channel.logoKey}
          isLink={!removeBranding}
        >
          <ThumbImage
            artworkKey={channel.logoKey}
            artworkMode={'logo'}
            alt={`Logo for ${channel.username}`}
          />
        </LogoImage>
        <div className={styles['full-screen__logo__details']}>
          <h2
            className={classNames(
              'truncate',
              styles['full-screen__logo__title']
            )}
          >
            {channel.username}
          </h2>
          {removeBranding === false && (
            <div className={styles['full-screen__logo__links']}>
              <SupportButton showAslink={true} />
              <FollowButtons
                selectedProfileId={channel.ownerId}
                followedIds={(currentUser && currentUser.followedUserIds) || []}
                showAslink={true}
              />
            </div>
          )}
        </div>
      </div>
      {renderLinks()}
      <PrivacyNotice />
    </header>
  );
};
