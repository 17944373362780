import { showAuthenticationEmailPromptForm } from 'features/current-user/currentUserSlice';
import React, { FunctionComponent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { ButtonLink } from 'components/Buttons/ButtonLink';
import { RootState } from 'rootReducer';
import { ExternalIconLink } from 'components/Buttons/ExternalIconLink';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useNativeEvents } from 'hooks/useNativeEvents';

export const ChatLoginPrompt: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { isNativeAppWebview } = useContext(UserAgentContext);
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const { nativeUrlSchemeLink } = useNativeEvents();

  if (isNativeAppWebview) {
    return (
      <div className={styles['chat__prompt']}>
        <ExternalIconLink
          label={'Login to join the crowd'}
          href={nativeUrlSchemeLink('mixlr', 'auth', {
            url: `${process.env.REACT_APP_WEBSITE_ENDPOINT}/signin`
          })}
          icon={'crowd'}
          iconSize={16}
          showLabel={true}
        />
      </div>
    );
  }

  return (
    <div className={styles['chat__prompt']}>
      <ButtonLink
        label={'Join the crowd'}
        onClick={() =>
          dispatch(
            showAuthenticationEmailPromptForm(
              `To chat with ${channel && channel.username}'s crowd`
            )
          )
        }
        icon={'crowd'}
        iconSize={16}
      />
    </div>
  );
};
