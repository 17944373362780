import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Icon } from '../Icons';
import { Link } from 'react-router-dom';

interface IconLinkProps {
  label: string;
  icon: string;
  href: string;
  showLabel?: boolean;
  isWhite?: boolean;
  cssClass?: string;
  iconSize?: number;
  noBackground?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  state?: any;
}

export const IconLink: FunctionComponent<IconLinkProps> = (props) => {
  return (
    <Link
      to={props.href}
      className={classNames(
        styles['button--icon'],
        styles[props.cssClass ? props.cssClass : ''],
        { [styles['button--icon-text']]: props.showLabel },
        { [styles['button--icon-white']]: props.isWhite },
        { [styles['button--icon-transparent']]: props.noBackground }
      )}
      onClick={props.onClick}
      state={props.state}
    >
      <Icon
        hidden={true}
        icon={props.icon}
        label={props.label}
        height={props.iconSize}
        width={props.iconSize}
      />
      <span className={classNames({ 'visually-hidden': !props.showLabel })}>
        {props.label}
      </span>
    </Link>
  );
};
