import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { Card } from 'components/Card';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { Loader } from 'components/Loader';
import { useSubdomain } from 'hooks/useSubdomain';
import { fetchRecordings } from 'features/recordings/recordingsSlice';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { allPublicRecordingsSelector } from 'features/recordings/recordingsSelectors';
import { EmptyRecordings } from 'features/channel/components/ChannelRecordings/EmptyRecordings';
import { Menu } from './Menu';

export interface InfiniteScrollRecordingsProps {
  itemsPerPage: number;
}

export const InfiniteScrollRecordings: FunctionComponent<
  InfiniteScrollRecordingsProps
> = ({ itemsPerPage }) => {
  const [currentItems, setCurrentItems] = useState<RecordingModel[]>([]);
  const [itemOffset, setItemOffset] = useState(0);
  const currentPageRef = useRef(1);
  const loading = useSelector((state: RootState) => state.recordings.isLoading);
  const pageCount = useSelector(
    (state: RootState) => state.recordings.pageCount
  );
  const subdomain = useSubdomain();
  const dispatch = useDispatch();
  const { ref, inView } = useInView({
    threshold: 0
  });
  const channelName = useSelector(
    (state: RootState) => state.channel.channelDetails?.username
  );
  const recordings: RecordingModel[] = useSelector(allPublicRecordingsSelector);

  useEffect(
    function fetchInitialRecordings() {
      dispatch(fetchRecordings(subdomain, 1));
    },
    [dispatch, subdomain]
  );

  useEffect(
    function setCurrentRecordings() {
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading recordings from ${itemOffset} to ${endOffset}`);
      setCurrentItems(recordings);
    },
    [itemOffset, itemsPerPage, recordings]
  );

  useEffect(
    function fetchRecordingsOnScroll() {
      if (inView && currentPageRef.current <= pageCount) {
        dispatch(fetchRecordings(subdomain, currentPageRef.current + 1));
        const newOffset = currentPageRef.current * itemsPerPage;
        setItemOffset(newOffset);
        currentPageRef.current += 1;
      }
    },
    [inView, dispatch, itemsPerPage, pageCount, subdomain]
  );

  if (recordings.length === 0 && !loading) {
    return (
      <div className="tw-flex tw-flex-col tw-h-full container--wide">
        <Menu />
        <EmptyRecordings username={channelName || ''} />
      </div>
    );
  }

  return (
    <div className="container--wide">
      <Menu />
      <h1 className="tw-sr-only">Recordings</h1>
      <ul className="list--reset grid">
        {currentItems.map((recording, index) => {
          const lastElement = index === currentItems.length - 1;
          return (
            <li
              className="col-xss-12 col-xs-6"
              key={recording.id}
              ref={lastElement ? ref : null}
            >
              <Card
                id={recording.id}
                url={`/recordings/${recording.id}`}
                title={`${recording.title}`}
                artworkKey={recording.artworkKey}
                artworkMode={'recording'}
                type={'recording'}
              />
            </li>
          );
        })}
      </ul>
      {loading && inView && (
        <div className="text--center mar-t mar-b">
          <Loader />
        </div>
      )}
    </div>
  );
};
