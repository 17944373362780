import React, { FunctionComponent, useContext } from 'react';
import { EventModel } from 'features/events/EventModel';
import { FullScreenControls } from 'components/Layouts/FullScreenView/FullScreenControls';
import { FullScreenDetails } from 'components/Layouts/FullScreenView/FullScreenDetails';
import { FullScreenFooter } from 'components/Layouts/FullScreenView/FullScreenFooter';
import { HeartButton } from 'components/Hearts/HeartButton';
import { ChatButton } from 'features/chat/components';
import { Share } from 'components/Share';
import { VolumeControl } from 'components/Player/VolumeControl';
import { EventMeta } from 'features/events/components/EventMeta';
import { ListenerCount } from 'components/ListenerCount';
import { useIsEventLive } from 'features/events/eventHooks';
import { EventInfo } from 'features/events/EventInfo';
import { setInfoVisible } from 'features/events/eventsSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { currentBroadcastFinishedSelector } from 'features/broadcast/selectors';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { VisualizerToggle } from 'components/Player/VisualizerToggle';

interface EventFooterProps {
  event: EventModel | null;
}

export const EventFooter: FunctionComponent<EventFooterProps> = ({ event }) => {
  const isLive = useIsEventLive(event);
  const dispatch = useDispatch();
  const currentBroadcastFinished = useAppSelector(
    currentBroadcastFinishedSelector
  );
  const nativeEventStarted = useSelector(
    (state: RootState) => state.events.nativeEventStarted
  );
  const nativeEventStopped = useSelector(
    (state: RootState) => state.events.nativeEventStopped
  );
  const { isCreatorAppWebview } = useContext(UserAgentContext);

  if (!event) {
    return null;
  }

  if (nativeEventStarted) {
    return null;
  }

  const footerContent = (
    <FullScreenFooter
      leftColumn={
        <>
          <VolumeControl />
          <FullScreenDetails
            title={event.title}
            meta={
              <>
                <EventMeta />
                <ListenerCount />
              </>
            }
            info={<EventInfo event={event} />}
            onInfoClick={() => dispatch(setInfoVisible(true))}
          />
        </>
      }
      rightColumn={
        <FullScreenControls>
          <HeartButton />
          <ChatButton />
          <VisualizerToggle />
          <Share title={event.title} label={'event'} />
        </FullScreenControls>
      }
    />
  );

  if (isLive) {
    return footerContent;
  } else {
    return currentBroadcastFinished ||
      (isCreatorAppWebview && nativeEventStopped)
      ? footerContent
      : null;
  }
};
