import React, { FunctionComponent, useEffect, useReducer } from 'react';
import styles from './styles.module.scss';
import { usePageVisibility } from 'hooks/usePageVisibility';
import { useAppSelector } from 'hooks/redux';
import { currentBroadcastHeartCountSelector } from 'features/broadcast/selectors';
import { formatNumber } from 'utilities/formatters';

export const HeartCount: FunctionComponent = () => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const heartCount = useAppSelector(currentBroadcastHeartCountSelector);
  const label = heartCount === 1 ? 'Heart' : 'Hearts';
  const isPageVisible = usePageVisibility();

  useEffect(() => {
    if (isPageVisible) {
      forceUpdate();
    }
  }, [isPageVisible]);

  return (
    <span className={styles['heart-button__count']}>
      {formatNumber(heartCount)}
      <span className="visually-hidden">{label}</span>
    </span>
  );
};
