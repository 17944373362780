import React, { ReactNode, FunctionComponent } from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { motion, useReducedMotion } from 'framer-motion';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';

interface Props {
  children?: ReactNode;
}

export const ChatAnimation: FunctionComponent<Props> = (props) => {
  const isChatFixedWidth = useMediaQuery({
    query: '(max-width: 739px)'
  });

  const isChatFixedHeight = useMediaQuery({
    query: '(max-height: 410px)'
  });

  const shouldReduceMotion = useReducedMotion();
  const isCrowdVisible = useSelector(
    (state: RootState) => state.crowd.isCrowdVisible
  );

  let variant = {};

  if (isChatFixedWidth || isChatFixedHeight) {
    variant = {
      visible: { y: 0, opacity: 1, scale: 1 },
      hidden: { y: '100%', opacity: 1, scale: 1 }
    };
  } else {
    variant = {
      visible: {
        opacity: 1,
        scale: 1,
        y: 0
      },
      hidden: {
        opacity: shouldReduceMotion ? 1 : 0,
        scale: shouldReduceMotion ? 1 : 0,
        y: 0
      }
    };
  }

  return (
    <motion.div
      className={classNames(styles['chat'], {
        [styles['chat--crowd-visible']]: isCrowdVisible
      })}
      initial={'hidden'}
      animate={'visible'}
      exit={'hidden'}
      transition={{ duration: shouldReduceMotion ? 0 : 0.3 }}
      style={{ originY: 1, originX: 0.8 }}
      variants={variant}
    >
      {props.children}
    </motion.div>
  );
};
