import React, { FunctionComponent } from 'react';
import { FullScreenContent } from 'components/Layouts/FullScreenView/FullScreenContent';
import { Visualizer } from 'components/Player/Visualizer';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { RecordingPlayer } from 'features/recordings/components/RecordingPlayer';
import { useLocalStorage } from 'usehooks-ts';

interface RecordingWrapperProps {
  recording: RecordingModel | null;
}

export const RecordingContent: FunctionComponent<RecordingWrapperProps> = ({
  recording
}) => {
  const [visualizerVisible] = useLocalStorage('visualizer-visible', true);

  if (!recording) {
    return null;
  }

  return (
    <FullScreenContent
      centerColumn={<RecordingPlayer />}
      visualizer={visualizerVisible && <Visualizer />}
    />
  );
};
