import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useCallback
} from 'react';
import { UserImage } from 'components/UserImage';
import { useCurrentUser, useIsBroadcaster } from 'features/current-user/hooks';
import { useDispatch } from 'react-redux';
import { logout } from 'features/current-user/thunks';
import styles from './styles.module.scss';
import fallbackImage from 'images/no-picture.svg';

export const CurrentUserMenu: FunctionComponent = () => {
  const detailRef = useRef<HTMLDetailsElement>(null);
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const isCurrentBroadcaster = useIsBroadcaster();

  const logoutClicked = () => {
    dispatch(logout());
  };

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (
      detailRef.current &&
      !detailRef.current.contains(e.target as HTMLElement)
    ) {
      detailRef.current.open = false;
    }
  }, []);

  const addDefaultSrc = (event: any): void => {
    if (!event.target.src.endsWith(fallbackImage)) {
      event.target.src = fallbackImage;
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  if (!currentUser) {
    return null;
  }

  return (
    <details ref={detailRef} className={styles['user-menu']}>
      <summary aria-haspopup="menu" aria-label="View and manage my account">
        <UserImage size={'mini'}>
          <img
            src={currentUser.profile_image_url}
            alt={`Profile image for ${currentUser.username}`}
            onError={addDefaultSrc}
          />
        </UserImage>
        <span className="visibility-hidden">My account</span>
      </summary>
      <div className={styles['user-menu__dropdown']} role="menu">
        <div className={styles['user-menu__details']}>
          <UserImage size={'medium'}>
            <img
              src={currentUser.profile_image_url}
              alt={`Profile image for ${currentUser.username}`}
              onError={addDefaultSrc}
            />
          </UserImage>
          <h4 className="mar-n">{currentUser.username}</h4>
          <ul className="list--reset text--center">
            {isCurrentBroadcaster && (
              <>
                <li>
                  <a
                    href={`${process.env.REACT_APP_CREATOR_ENDPOINT}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Creator dashboard
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_CREATOR_ENDPOINT}/channel/edit`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Channel settings
                  </a>
                </li>
              </>
            )}
            <li>
              <a
                href={`${process.env.REACT_APP_WEBSITE_ENDPOINT}/settings/account`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Manage my account
              </a>
            </li>
          </ul>
        </div>
        <button className={styles['user-menu__logout']} onClick={logoutClicked}>
          <span>Log out</span>
        </button>
      </div>
    </details>
  );
};
