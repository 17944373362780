import React, { FunctionComponent } from 'react';
import { EventModel } from 'features/events/EventModel';
import { FullScreenControls } from 'components/Layouts/FullScreenView/FullScreenControls';
import { FullScreenDetails } from 'components/Layouts/FullScreenView/FullScreenDetails';
import { FullScreenFooter } from 'components/Layouts/FullScreenView/FullScreenFooter';
import { HeartButton } from 'components/Hearts/HeartButton';
import { ChatButton } from 'features/chat/components';
import { Share } from 'components/Share';
import { EventMeta } from 'features/events/components/EventMeta';
import { ListenerCount } from 'components/ListenerCount';
import { useIsEventLive } from 'features/events/eventHooks';
import { EventInfo } from 'features/events/EventInfo';
import { setInfoVisible } from 'features/events/eventsSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import { currentBroadcastFinishedSelector } from 'features/broadcast/selectors';

interface EventFooterProps {
  event: EventModel | null;
}

export const EventCreatorFooter: FunctionComponent<EventFooterProps> = ({
  event
}) => {
  const isLive = useIsEventLive(event);
  const dispatch = useDispatch();
  const nativeEventStopped = useAppSelector(
    (state) => state.events.nativeEventStopped
  );
  const currentBroadcastFinished = useAppSelector(
    currentBroadcastFinishedSelector
  );

  if (!event) {
    return null;
  }

  const footerContent = (
    <FullScreenFooter
      leftColumn={
        <FullScreenDetails
          title={event.title}
          meta={
            <>
              <EventMeta />
              <ListenerCount />
            </>
          }
          info={<EventInfo event={event} />}
          onInfoClick={() => dispatch(setInfoVisible(true))}
        />
      }
      rightColumn={
        <FullScreenControls>
          <HeartButton />
          <ChatButton />
          <Share title={event.title} label={'event'} />
        </FullScreenControls>
      }
    />
  );

  if (isLive || currentBroadcastFinished || nativeEventStopped) {
    return footerContent;
  } else {
    return null;
  }
};
