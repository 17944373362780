import React, { FunctionComponent, useContext } from 'react';
import { PaginatedRecordings } from 'features/channel/components/ChannelRecordings/PaginatedRecordings';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { InfiniteScrollRecordings } from './InifinteScrollRecordings';

export const ChannelRecordings: FunctionComponent = () => {
  const { isListenerAppWebview } = useContext(UserAgentContext);

  if (isListenerAppWebview) {
    return <InfiniteScrollRecordings itemsPerPage={20} />;
  }

  return <PaginatedRecordings itemsPerPage={20} />;
};
