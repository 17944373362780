import React, { FunctionComponent, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { FullImage } from 'components/Images/FullImage';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useNativeEvents } from 'hooks/useNativeEvents';

interface FullScreenWrapperProps {
  children?: React.ReactNode;
  artworkKey: string | null;
  artworkMode?: string | undefined;
  updatedTimestamp?: string;
  type: 'event' | 'recording' | 'private';
  chatVisible?: boolean | undefined | null;
}

export const FullScreenWrapper: FunctionComponent<FullScreenWrapperProps> = (
  props
) => {
  const { isListenerAppWebview } = useContext(UserAgentContext);
  const { postMessage } = useNativeEvents();

  const hasImage =
    (props.artworkKey && props.artworkMode !== 'theme') ||
    props.artworkMode === 'channel';

  useEffect(() => {
    if (isListenerAppWebview) {
      postMessage({
        hasImage: hasImage
      });
    }
  }, [hasImage, isListenerAppWebview, postMessage]);

  return (
    <div
      className={classNames(styles['full-screen__wrapper'], {
        [styles['full-screen__wrapper--image']]: hasImage,
        'chat-visible': props.chatVisible,
        'has-image': hasImage
      })}
    >
      <FullImage
        artworkKey={props.artworkKey}
        artworkMode={props.artworkMode}
        className={styles['full-screen__image']}
        updatedTimestamp={props.updatedTimestamp}
        type={props.type}
      />
      {props.children}
    </div>
  );
};
