import React, { FunctionComponent, useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { EventTimer } from 'features/events/EventTimer';
import styles from '../styles.module.scss';
import { useCurrentEvent, useIsEventLive } from 'features/events/eventHooks';
import { useAppSelector } from 'hooks/redux';
import { currentEventBroadcastSelector } from 'features/broadcast/selectors';
import useNetwork from 'hooks/useNetwork';
import { UserAgentContext } from 'features/user-agent/userAgent';

export const EventMeta: FunctionComponent = () => {
  const loading = useSelector((state: RootState) => state.audio.loading);
  const playing = useSelector((state: RootState) => state.audio.playing);
  const connecting = useSelector((state: RootState) => state.audio.connecting);
  const { isOnline } = useNetwork();
  const { isCreatorAppWebview } = useContext(UserAgentContext);

  const event = useCurrentEvent();
  const currentBroadcast = useAppSelector(currentEventBroadcastSelector);

  const isLive = useIsEventLive(event);

  if (!event || !currentBroadcast || isCreatorAppWebview) {
    return null;
  }

  const renderStatusText = () => {
    if (isLive) {
      if (!isOnline) {
        return 'Offline';
      } else if (connecting || loading) {
        return <span className="loading-text">Connecting</span>;
      } else if (playing) {
        return <EventTimer event={event} broadcast={currentBroadcast} />;
      }
      return 'On Air';
    }
    return 'Off-Air';
  };

  return (
    <div className={styles['event-view__player-state']}>
      {renderStatusText()}
    </div>
  );
};
