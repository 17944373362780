import React, { FunctionComponent, useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { Card } from 'components/Card';
import { EventModel } from 'features/events/EventModel';
import { RecordingModel } from 'features/recordings/RecordingModel';
import {
  upcomingEventSelector,
  liveEventSelector
} from 'features/events/eventsSelectors';
import { latestRecordingsSelector } from 'features/recordings/recordingsSelectors';
import { ChannelDetails } from '../ChannelDetails';
import { ChannelInfo } from '../ChannelInfo';
import { SwiperList } from 'components/Swipers/SwiperList';
import { Loader } from 'components/Loader';
import styles from './styles.module.scss';
import { useSubdomain } from 'hooks/useSubdomain';
import { fetchRecordings } from 'features/recordings/recordingsSlice';
import { useDispatch } from 'react-redux';
import { useOnceEffect } from 'hooks/useOnceEffect';
import { UserAgentContext } from 'features/user-agent/userAgent';

export const ChannelHome: FunctionComponent = () => {
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const channelisLoaded = useSelector(
    (state: RootState) => state.channel.isLoaded
  );

  const upcomingEvents: EventModel[] = useSelector(
    upcomingEventSelector
  ).filter((event) => event.isUpcoming());

  const latestRecordings: RecordingModel[] = useSelector(
    latestRecordingsSelector
  );

  const eventsLoading = useSelector(
    (state: RootState) => state.events.isLoading
  );
  const recordingsLoading = useSelector(
    (state: RootState) => state.recordings.isLoading
  );

  const liveEvent: EventModel | null = useSelector(liveEventSelector);
  const subdomain = useSubdomain();
  const dispatch = useDispatch();

  useOnceEffect(function fetchInitialRecordings() {
    dispatch(fetchRecordings(subdomain, 1));
  });

  const { isNativeAppWebview } = useContext(UserAgentContext);

  if (!channelisLoaded || channel == null) {
    return null;
  }

  if (
    (eventsLoading && upcomingEvents.length === 0) ||
    (recordingsLoading && latestRecordings.length === 0)
  ) {
    return <Loader fillComponent={true} hideOnNative={isNativeAppWebview} />;
  }

  if (
    upcomingEvents.length === 0 &&
    latestRecordings.length === 0 &&
    !liveEvent
  ) {
    return (
      <section className={styles['channel-home__empty']}>
        <ChannelInfo />
        <ChannelDetails />
      </section>
    );
  }

  const featuredUpcomingEvent = !liveEvent && upcomingEvents.length > 0;
  const featuredRecording =
    !liveEvent && !featuredUpcomingEvent && latestRecordings.length > 0;
  const [, ...featuredUpcomingEventList] = upcomingEvents;
  const [, ...featuredRecordingList] = latestRecordings;

  return (
    <section className={styles['channel-home']}>
      <ChannelInfo />
      {liveEvent && (
        <Card
          id={liveEvent.id}
          url={`/events/${liveEvent.id}`}
          title={`${liveEvent.title}`}
          artworkKey={liveEvent.artworkKey}
          artworkMode={liveEvent.artworkMode}
          isPrivate={liveEvent.isPrivate()}
          isActive={liveEvent.isLive()}
          updatedTimestamp={liveEvent.lastUpdatedAt}
          isFeatured={true}
          type={'event'}
        />
      )}
      {featuredUpcomingEvent && (
        <Card
          id={upcomingEvents[0].id}
          url={`/events/${upcomingEvents[0].id}`}
          title={`${upcomingEvents[0].title}`}
          artworkKey={upcomingEvents[0].artworkKey}
          artworkMode={upcomingEvents[0].artworkMode}
          isPrivate={upcomingEvents[0].isPrivate()}
          isActive={upcomingEvents[0].isLive()}
          updatedTimestamp={upcomingEvents[0].lastUpdatedAt}
          isFeatured={true}
          type={'event'}
        />
      )}
      {featuredRecording && (
        <Card
          id={latestRecordings[0].id}
          url={`/recordings/${latestRecordings[0].id}`}
          title={`${latestRecordings[0].title}`}
          artworkKey={latestRecordings[0].artworkKey}
          artworkMode={'recording'}
          isFeatured={true}
          type={'recording'}
        />
      )}
      <SwiperList
        title={'Upcoming events'}
        url={'/events'}
        eventData={
          featuredUpcomingEvent ? featuredUpcomingEventList : upcomingEvents
        }
      />
      <SwiperList
        title={'Latest recordings'}
        url={'/recordings'}
        recordingData={
          featuredRecording ? featuredRecordingList : latestRecordings
        }
      />
    </section>
  );
};
