import React, { FunctionComponent, ReactNode } from 'react';
import { FullScreenWrapper } from 'components/Layouts/FullScreenView/FullScreenWrapper';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { UnlistedBanner } from 'components/UnlistedBanner';

interface RecordingWrapperProps {
  children: ReactNode;
  recording: RecordingModel | null;
}

export const RecordingWrapper: FunctionComponent<RecordingWrapperProps> = ({
  children,
  recording
}) => {
  if (!recording) {
    return null;
  }

  return (
    <FullScreenWrapper
      artworkKey={recording.artworkKey}
      artworkMode={'recording'}
      type={'recording'}
    >
      <UnlistedBanner />
      {children}
    </FullScreenWrapper>
  );
};
