import React, { FunctionComponent } from 'react';
import { ReactComponent as RecordingsEmptySVG } from 'images/empty-recordings.svg';

export interface EmptyRecordingsProps {
  username: string;
}

export const EmptyRecordings: FunctionComponent<EmptyRecordingsProps> = ({
  username
}) => {
  return (
    <div className="empty-state">
      <div className="empty-state__content">
        <RecordingsEmptySVG />
        <p>{username} does not have any recordings.</p>
      </div>
    </div>
  );
};
