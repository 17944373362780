import { Icon } from 'components/Icons';
import { setCurrentCollectionId } from 'features/collections/collectionsSlice';
import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

export const CollectionBackLink: FunctionComponent = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setCurrentCollectionId(null));
  };

  return (
    <div className="tw-border-[0] tw-border-t tw-border-solid tw-border-line-color [.ios-listener_&]:tw-border-t-0 [.android-listener_&]:tw-border-t-0">
      <Link
        to={'/collections'}
        className="tw-flex tw-items-center tw-gap-2 tw-p-4 [.ios-listener_&]:tw-py-3 [.android-listener_&]:tw-py-3"
        onClick={onClick}
      >
        <Icon
          hidden={true}
          icon={'arrowLeft'}
          label={'Go back'}
          height={14}
          width={14}
        />
        Back to collections
      </Link>
    </div>
  );
};
