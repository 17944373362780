import React, { FunctionComponent } from 'react';
import { IconButton } from 'components/Buttons/IconButton';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { LogoImage } from 'components/LogoImage';
import { ThumbImage } from 'components/Images/ThumbImage';
import styles from './styles.module.scss';

interface ModalHeaderProps {
  title?: string;
  showChannelLogo?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ModalHeader: FunctionComponent<ModalHeaderProps> = (props) => {
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );

  return (
    <div className={styles['modal__header']}>
      {props.showChannelLogo && channel && (
        <div className={styles['modal__header-logo']}>
          <LogoImage size={'medium'}>
            <ThumbImage
              artworkKey={channel.logoKey}
              artworkMode={'logo'}
              alt={''}
            />
          </LogoImage>
        </div>
      )}
      {props.title && (
        <h4 className={styles['modal__header-title']}>{props.title}</h4>
      )}
      <div className={styles['modal__header-close']}>
        <IconButton
          label={'Close login'}
          cssClass={'button--close'}
          onClick={props.onClick}
          icon={'x'}
        />
      </div>
    </div>
  );
};
