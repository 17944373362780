export interface CloudflareOptions {
  width?: number;
  height?: number;
  quality?: number;
  dpr?: number;
  baseUrl?: string;
  format?: string;
  fit?: string;
}

export const cloudflareUrl = (
  key?: string | null,
  { width, height, quality, baseUrl, dpr, format, fit }: CloudflareOptions = {}
): string | null => {
  if (!key) {
    return null;
  }

  if (!baseUrl || key.includes('/original')) {
    baseUrl = process.env.REACT_APP_ASSETS_CDN_URL;
  }

  const options = ['format=auto', 'fit=contain', 'gravity=auto'];
  if (width) {
    options.push(`width=${width.toString()}`);
  }

  if (height) {
    options.push(`height=${height.toString()}`);
  }

  if (quality) {
    options.push(`quality=${quality.toString()}`);
  }

  if (dpr) {
    options.push(`dpr=${dpr.toString()}`);
  }

  if (format) {
    options.push(`format=${format.toString()}`);
  }

  if (fit) {
    options.push(`fit=${fit.toString()}`);
  }

  const optionsStr = options.join(',').concat(options.length > 0 ? '/' : '');

  return `${baseUrl}${optionsStr}${key}`;
};
