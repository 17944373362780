import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Icon } from '../Icons';

interface ExternalIconLinkProps {
  label: string;
  icon?: string;
  href: string;
  showLabel?: boolean;
  target?: string;
  rel?: string;
  cssClass?: string;
  iconSize?: number;
}

export const ExternalIconLink: FunctionComponent<ExternalIconLinkProps> = (
  props
) => {
  return (
    <a
      href={props.href}
      className={classNames(
        styles['external-link'],
        styles[props.cssClass ? props.cssClass : ''],
        { [styles['external-link--text']]: props.showLabel }
      )}
      {...(props.target && { target: props.target })}
      {...(props.rel && { rel: props.rel })}
    >
      {props.icon && (
        <Icon
          hidden={true}
          icon={props.icon}
          label={props.label}
          height={props.iconSize}
          width={props.iconSize}
        />
      )}
      <span
        className={classNames('truncate', {
          'visually-hidden': !props.showLabel
        })}
      >
        {props.label}
      </span>
    </a>
  );
};
