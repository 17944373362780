import React, { FunctionComponent, useEffect, useContext } from 'react';
import { SEOHeaderTags } from 'components/SEO/SEOHeaderTags';
import { EventModel } from 'features/events/EventModel';
import { cloudflareUrl } from 'utilities/cloudflareImageResizer';
import { formatNumber } from 'utilities/formatters';
import { useAppSelector } from 'hooks/redux';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useSEOTitleDescription } from 'hooks/useSEOTitleDescription';

interface EventHeaderTagsProps {
  event: EventModel | null;
}

export const EventHeaderTags: FunctionComponent<EventHeaderTagsProps> = ({
  event
}) => {
  const hasNewComments = useAppSelector((state) => state.chat.hasNewComments);
  const newCommentCount = useAppSelector((state) => state.chat.newCommentCount);
  const { isListenerAppWebview } = useContext(UserAgentContext);
  const { pageTitle, pageDescription } = useSEOTitleDescription(
    event?.title,
    event?.description
  );

  useEffect(
    function showBrowserTabCount() {
      if (!event || isListenerAppWebview) return;
      if (hasNewComments && newCommentCount > 0) {
        document.title = `(${formatNumber(newCommentCount)}) ${pageTitle}`;
      } else {
        document.title = pageTitle;
      }
    },
    [hasNewComments, newCommentCount, event, isListenerAppWebview, pageTitle]
  );

  if (!event) {
    return null;
  }

  const imgUrl = cloudflareUrl(event.artworkKey, {
    width: 800,
    quality: 80
  });

  return (
    <SEOHeaderTags
      title={pageTitle}
      description={pageDescription}
      image={imgUrl}
      url={window.location.href}
    />
  );
};
