import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEvent } from 'features/events/eventHooks';
import styles from 'features/events/styles.module.scss';
import { Share } from 'components/Share';
import { EventDate } from 'features/events/EventDate';
import { EventCountdownTimer } from 'features/events/components/EventCountdownTimer';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { EventInfo } from 'features/events/EventInfo';
import { ButtonLink } from 'components/Buttons/ButtonLink';
import { setInfoVisible } from 'features/events/eventsSlice';
import { useDispatch } from 'react-redux';

export const EventCreatorCountdown: FunctionComponent = () => {
  const { id } = useParams();
  const event = useEvent(id);
  const nativeEventStopped = useSelector(
    (state: RootState) => state.events.nativeEventStopped
  );
  const calculateTimeLeft = (): string | undefined => {
    return event?.durationToEvent()?.toFormat('hh:mm:ss');
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isOverdue, setIsOverdue] = useState(event?.isOverdue());
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      setIsOverdue(event?.isOverdue());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const Overdue = () => {
    if (event?.startedAt || event?.endedAt) {
      return <p>Restart your live event</p>;
    }
    return <p>Event overdue, your listeners are waiting!</p>;
  };

  if (!event) {
    return null;
  }

  if (nativeEventStopped) {
    return null;
  }

  if (event.isAdhoc()) {
    return (
      <div className={styles['countdown']}>
        {!event?.endedAt ? (
          <>
            <p>
              Click <strong>Start</strong> to go live with...
            </p>
            <h2>{event.title}</h2>
            <Share title={event.title} label={'event'} showLabel={true} />
          </>
        ) : (
          <Overdue />
        )}
      </div>
    );
  }

  return (
    <div className={styles['countdown']}>
      {!event.endedAt && <span>Next event:</span>}
      <h2 className="mar-n truncate-multiline">{event.title}</h2>
      <div className={styles['countdown__date']}>
        <EventDate eventId={event.id} hideTime={event.isIn24hrs()} />
      </div>
      <ButtonLink
        label={'View event details'}
        icon={'info'}
        iconSize={16}
        onClick={() => dispatch(setInfoVisible(true))}
      />
      {isOverdue || event.startedAt ? (
        <Overdue />
      ) : (
        <EventCountdownTimer event={event} timeLeft={timeLeft} />
      )}
      <Share title={event.title} label={'event'} showLabel={true} />
      <EventInfo event={event} />
    </div>
  );
};
