import React, { FunctionComponent, useContext } from 'react';
import styles from './styles.module.scss';
import { FollowButtons } from 'features/chat/components/FollowButtons';
import { SupportButton } from 'components/Support/SupportButton';
import { ChannelSocialLinks } from 'features/channel/components/ChannelSocialLinks';
import { useCurrentUser } from 'features/current-user/hooks';
import classNames from 'classnames';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { IconButton } from 'components/Buttons/IconButton';
import { useDispatch } from 'react-redux';
import { toggleSearchVisibility } from 'features/search/searchSlice';
import { useAppSelector } from 'hooks/redux';

export const ChannelInfo: FunctionComponent = () => {
  const channel = useAppSelector((state) => state.channel.channelDetails);
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const { isNativeAppWebview, isCreatorAppWebview } =
    useContext(UserAgentContext);

  const showSearch = () => {
    dispatch(toggleSearchVisibility(true));
  };

  if (!channel || isCreatorAppWebview) {
    return null;
  }

  return (
    <div className={styles['channel-info']}>
      {!isNativeAppWebview && (
        <h1 className={classNames(styles['channel-info__title'], 'truncate')}>
          {channel.username}
        </h1>
      )}
      <div className={styles['channel-info__links']}>
        <ChannelSocialLinks iconList={true} />
        <SupportButton />
        <FollowButtons
          selectedProfileId={channel.ownerId}
          followedIds={(currentUser && currentUser.followedUserIds) || []}
        />
        {isNativeAppWebview && (
          <div className="tw-ml-auto">
            <IconButton
              label={'Show search'}
              onClick={showSearch}
              size={'small'}
              icon={'search'}
              iconSize={24}
              noBackground={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};
