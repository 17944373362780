import classNames from 'classnames';
import { IconButton } from 'components/Buttons/IconButton';
import {
  deleteChatMessage,
  setSelectedChatUserId
} from 'features/chat/chatSlice';
import { useCurrentUser, useIsBroadcaster } from 'features/current-user/hooks';
import { motion, useReducedMotion } from 'framer-motion';
import React, { FunctionComponent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import styles from './styles.module.scss';
import buttonStyles from 'components/Buttons/styles.module.scss';
import { DateTime } from 'luxon';
import { Icon } from 'components/Icons';
import { UserImage } from 'components/UserImage';
import fallbackImage from 'images/no-picture.svg';
import { UserAgentContext } from 'features/user-agent/userAgent';
import sanitizeHtml from 'sanitize-html';
import linkifyHtml from 'linkify-html';

interface ChatCommentProps {
  id: string;
  comment: string;
  user: string;
  userId: string;
  date: DateTime;
  img: string;
  type: string;
  heartScale: number;
}

export const ChatComment: FunctionComponent<ChatCommentProps> = (props) => {
  const dispatch = useDispatch();
  const socketId = useSelector((state: RootState) => state.hub.socketId);
  const isBroadcaster = useIsBroadcaster();
  const currentUser = useCurrentUser();
  const canDeleteComment = isBroadcaster || currentUser?.id === props.userId;
  const shouldReduceMotion = useReducedMotion();
  const channelOwnerId = useSelector(
    (state: RootState) => state.channel.channelDetails?.ownerId
  );
  const { isNativeAppWebview } = useContext(UserAgentContext);

  const showProfile = () => {
    dispatch(
      setSelectedChatUserId({
        id: props.userId,
        username: props.user,
        profileImageUrl: props.img
      })
    );
  };

  const deleteMessage = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (window.confirm('Delete this comment?')) {
      dispatch(deleteChatMessage(props.id, socketId));
    }
  };

  const linkifyOptions = {
    target: '_blank',
    rel: 'noopener',
    format: {
      url: (value: string) =>
        value.length > 50 ? value.slice(0, 50) + '…' : value
    },
    className: 'comment__url',
    ignoreTags: ['img']
  };

  const santizeComment = (comment: string) => {
    const giphyUrls =
      /(https?:\/\/(.+?\.)?giphy\.com(\/[A-Za-z0-9\-._~:#\]@!$&',;]*)?)/;

    if (giphyUrls.test(comment)) {
      comment = `<img src="${comment}" alt="">`;
    }

    const cleanComment = sanitizeHtml(comment, {
      allowedTags: ['a', 'img'],
      allowedAttributes: {
        a: ['href', 'target'],
        img: ['src', 'alt'],
        allowedSchemes: ['https']
      },
      exclusiveFilter: (frame) => {
        return frame.tag === 'img' && !giphyUrls.test(frame.attribs.src);
      }
    });
    return (
      <p
        className={styles['comment__text__block']}
        dangerouslySetInnerHTML={{
          __html: linkifyHtml(cleanComment, linkifyOptions)
        }}
      ></p>
    );
  };

  if (props.type === 'heart') {
    return (
      <motion.li
        key={'heart'}
        initial={isNativeAppWebview ? false : { opacity: 0, y: 5 }}
        animate={isNativeAppWebview ? false : { opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 5 }}
        transition={{ duration: shouldReduceMotion ? 0 : 0.1 }}
        className={styles['comment-wrapper--heart']}
      >
        <div className={styles['comment--heart']}>
          <motion.div
            className={styles['comment--heart__icon']}
            style={{
              scale: 0.4
            }}
            animate={{ scale: props.heartScale }}
          >
            <Icon
              hidden={true}
              icon={'heart'}
              label={''}
              height={24}
              width={24}
            />
          </motion.div>
          <span>{props.comment}</span>
        </div>
      </motion.li>
    );
  }

  const addDefaultSrc = (event: any): void => {
    if (!event.target.src.endsWith(fallbackImage)) {
      event.target.src = fallbackImage;
    }
  };

  return (
    <motion.li
      key={'comment'}
      initial={isNativeAppWebview ? false : { opacity: 0, y: 5 }}
      animate={isNativeAppWebview ? false : { opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 5 }}
      transition={{ duration: shouldReduceMotion ? 0 : 0.1 }}
      className={styles['comment-wrapper']}
    >
      <div
        className={classNames(styles['comment'], {
          [styles['comment--broadcaster']]: channelOwnerId == props.userId
        })}
      >
        <div className={styles['comment__profile']}>
          <UserImage>
            <img
              src={props.img}
              alt={`Profile image for ${props.user}`}
              onError={addDefaultSrc}
              onClick={showProfile}
            />
          </UserImage>
        </div>
        <div className={styles['comment__content']}>
          <div className={styles['comment__meta']}>
            <button
              className={classNames(
                buttonStyles['button--link'],
                styles['comment__username']
              )}
              onClick={showProfile}
            >
              {props.user}
            </button>
            <span className={classNames('tnum', styles['comment__time'])}>
              {props.date.toRelative({ style: 'short' })}
            </span>
          </div>
          <div
            className={classNames(styles['comment__text'], {
              'comment__text--actions': canDeleteComment
            })}
          >
            {santizeComment(props.comment)}
            {canDeleteComment && (
              <div className={styles['comment__actions']}>
                <IconButton
                  icon={'trash'}
                  label={'Delete'}
                  size={'mini'}
                  showLabel={false}
                  noBackground={true}
                  onClick={(event) => deleteMessage(event)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.li>
  );
};
