import React, { FunctionComponent } from 'react';
import { RecordingModel } from 'features/recordings/RecordingModel';
import { FullScreenControls } from 'components/Layouts/FullScreenView/FullScreenControls';
import { FullScreenDetails } from 'components/Layouts/FullScreenView/FullScreenDetails';
import { FullScreenFooter } from 'components/Layouts/FullScreenView/FullScreenFooter';
import { Share } from 'components/Share';
import { RecordingMeta } from 'features/recordings/components/RecordingMeta';
import { VolumeControl } from 'components/Player/VolumeControl';
import { useAppSelector } from 'hooks/redux';

interface RecordingEmbedFooterProps {
  recording: RecordingModel | null;
}

export const RecordingEmbedFooter: FunctionComponent<
  RecordingEmbedFooterProps
> = ({ recording }) => {
  const removeBranding = useAppSelector(
    (state) => state.channel?.channelDetails?.removeEmbedBranding || false
  );

  if (!recording) {
    return null;
  }

  return (
    <FullScreenFooter
      leftColumn={
        <>
          <VolumeControl />
          <FullScreenDetails title={recording.title} meta={<RecordingMeta />} />
        </>
      }
      rightColumn={
        <FullScreenControls>
          <></>
          {!removeBranding && (
            <Share title={recording.title} label={'recording'} />
          )}
        </FullScreenControls>
      }
    />
  );
};
