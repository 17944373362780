import React, { FunctionComponent } from 'react';
import { cloudflareUrl } from 'utilities/cloudflareImageResizer';
import fallbackImage from 'images/no-picture.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useArtworkKey } from 'components/Images/imageHooks';
import { ReactComponent as EventBackground } from 'images/event-background.svg';
import { ReactComponent as RecordingBackground } from 'images/recording-background.svg';

interface ThumbImageProps {
  artworkKey: string | null | undefined;
  artworkMode?: string | undefined;
  className?: string;
  alt?: string;
  fallback?: string;
  type?: 'event' | 'recording';
}

export const ThumbImage: FunctionComponent<ThumbImageProps> = ({
  artworkKey,
  artworkMode,
  className,
  alt,
  fallback,
  type
}) => {
  const { imageKey, imageBaseUrl } = useArtworkKey(artworkMode, artworkKey);

  const generateImageUrl = (imageDpr: number): string | undefined => {
    return (
      cloudflareUrl(imageKey, {
        height: 100,
        width: 100,
        quality: 80,
        dpr: imageDpr,
        baseUrl: imageBaseUrl
      }) ||
      fallback ||
      fallbackImage
    );
  };

  const addDefaultSrc = (event: any): void => {
    if (!event.target.src.endsWith(fallbackImage)) {
      event.target.src = fallbackImage;
    }
  };

  if (!imageKey && artworkMode === 'logo') {
    return (
      <img src={fallbackImage} alt="" className={styles['logo-fallback']} />
    );
  }

  if (!imageKey || artworkMode === 'theme') {
    return type === 'recording' ? <RecordingBackground /> : <EventBackground />;
  }

  return (
    <img
      srcSet={`${generateImageUrl(1)} 1x, 
         ${generateImageUrl(2)} 2x,
         ${generateImageUrl(3)} 3x`}
      src={generateImageUrl(1)}
      alt={alt ?? ''}
      loading="lazy"
      className={classNames(className, {
        [styles['logo-fallback']]: !imageKey
      })}
      onError={addDefaultSrc}
    />
  );
};
