import React, {
  FunctionComponent,
  useState,
  useEffect,
  SyntheticEvent,
  useContext
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import {
  validateEventAccesscode,
  fetchEvent
} from 'features/events/eventsSlice';
import styles from './styles.module.scss';
import { Button } from 'components/Buttons/Button';
import { Event } from 'features/events/eventsSlice';
import { IconLink } from 'components/Buttons/IconLink';
import { Loader } from 'components/Loader';
import { EventDate } from 'features/events/EventDate';
import { setMiniPlayerVisible } from 'features/channel/channelSlice';
import { useSubdomain } from 'hooks/useSubdomain';
import { ThumbImage } from 'components/Images/ThumbImage';
import { LogoImage } from 'components/LogoImage';
import { UserAgentContext } from 'features/user-agent/userAgent';

interface PrivateEventProps {
  event: Event;
}

export const PrivateEvent: FunctionComponent<PrivateEventProps> = (
  props: PrivateEventProps
) => {
  const [accesscode, setAccesscode] = useState('');
  const dispatch = useDispatch();
  const accesscodeDetails = props.event;
  const subdomain = useSubdomain();
  const { isEmbed } = useContext(UserAgentContext);

  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );

  const { isLoading } = useSelector((state: RootState) => state.events);

  const submitForm = (event: SyntheticEvent) => {
    event.preventDefault();

    if (accesscode.length > 0) {
      dispatch(
        validateEventAccesscode(subdomain, accesscodeDetails.id, accesscode)
      );
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccesscode(event.target.value);
  };

  const id = accesscodeDetails.id;

  useEffect(() => {
    dispatch(fetchEvent(subdomain, id));
  }, [id, dispatch, subdomain]);

  const onMinimize = () => {
    dispatch(setMiniPlayerVisible(true));
  };

  if (isLoading) return <Loader fillComponent={true} />;

  return (
    <div className={styles['access-modal']}>
      {channel && (
        <LogoImage
          size={isEmbed ? 'small' : 'medium'}
          hasFallback={!channel.logoKey}
        >
          <ThumbImage
            artworkKey={channel.logoKey}
            artworkMode={'logo'}
            alt={`Logo for ${channel.username}`}
          />
        </LogoImage>
      )}
      {props.event && (
        <>
          <h4 className="mar-n">{props.event.title}</h4>
          <EventDate eventId={props.event.id} />
        </>
      )}
      <form action="" method="get" className="form">
        <div className="form__row">
          <label htmlFor="private_access_code" className="form__label">
            Enter access code to join
          </label>
          <input
            className="form__input form__input--medium form__input--center text--center"
            type="password"
            name="private_access_code"
            onChange={handleInputChange}
          />
          {accesscodeDetails.accessFailed && (
            <div className="form__error">Access code incorrect</div>
          )}
        </div>
        <div className="form__row form__row--submit">
          <Button onClick={submitForm}>Join</Button>
        </div>
      </form>
      <IconLink
        showLabel={false}
        icon={'chevronDown'}
        label={'Minimize event'}
        href={'/'}
        onClick={onMinimize}
        isWhite={true}
        cssClass={'button--minimise'}
      />
    </div>
  );
};
