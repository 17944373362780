import React, { FunctionComponent, useContext } from 'react';
import { Grid, SearchContext } from '@giphy/react-components';
import { IGif } from '@giphy/js-types';

interface GiphyPickerProps {
  onGifClick?:
    | ((gif: IGif, e: React.SyntheticEvent<HTMLElement, Event>) => void)
    | undefined;
}

export const GiphySearchResults: FunctionComponent<GiphyPickerProps> = (
  props
) => {
  const { fetchGifs, searchKey } = useContext(SearchContext);
  return (
    <div className="tw-flex-1 tw-overflow-y-scroll tw-overflow-x-hidden">
      <Grid
        className="tw-w-full"
        key={searchKey}
        columns={2}
        width={260}
        fetchGifs={fetchGifs}
        onGifClick={props.onGifClick}
        hideAttribution={true}
        noLink={true}
      />
    </div>
  );
};
