import React, { FunctionComponent } from 'react';
import { useAppSelector } from 'hooks/redux';
import Modal from 'react-modal';
import styles from './styles.module.scss';

interface SearchWrapperProps {
  children?: React.ReactNode;
}

export const SearchWrapper: FunctionComponent<SearchWrapperProps> = ({
  children
}) => {
  const isVisible = useAppSelector((state) => state.search.isVisible);

  return (
    <Modal
      isOpen={isVisible}
      className={styles['search']}
      overlayClassName={styles['search__overlay']}
      ariaHideApp={false}
      contentLabel={'Search events & recordings'}
    >
      <div className={styles['search__wrapper']}>{children}</div>
    </Modal>
  );
};
