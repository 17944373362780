import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Icon } from '../Icons';
import styles from './styles.module.scss';

interface IconButtonProps {
  label: any;
  type?: 'button' | 'submit';
  icon: string;
  isDisabled?: boolean;
  showLabel?: boolean;
  tooltip?: boolean;
  ariaControls?: string;
  ariaExpanded?: boolean;
  outline?: boolean;
  size?: string;
  cssClass?: string;
  noBackground?: boolean;
  iconSize?: number;
  isHighlighted?: boolean;
  isLoading?: boolean;
  showNotification?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const IconButton: FunctionComponent<IconButtonProps> = (props) => {
  return (
    <button
      aria-label={props.label}
      className={classNames(
        styles['button--icon'],
        styles[props.cssClass ? props.cssClass : ''],
        { [styles['button--icon-text']]: props.showLabel },
        { [styles['button--icon-small']]: props.size === 'small' },
        { [styles['button--icon-mini']]: props.size === 'mini' },
        { [styles['button--icon-transparent']]: props.noBackground },
        { [styles['button--icon-highlight']]: props.isHighlighted },
        { [styles['button--icon-transparent']]: props.noBackground },
        { [styles['button--icon-outline']]: props.outline },
        { [styles['button--icon-loading']]: props.isLoading },
        { [styles['button--icon-notification']]: props.showNotification }
      )}
      onClick={props.onClick}
      type={props.type ? props.type : 'button'}
      {...(props.ariaControls && { 'aria-controls': props.ariaControls })}
      {...(props.ariaExpanded !== undefined && {
        'aria-expanded': props.ariaExpanded
      })}
      {...(props.isDisabled && { disabled: props.isDisabled })}
    >
      <Icon
        hidden={true}
        icon={props.icon}
        label={props.label}
        height={props.iconSize}
        width={props.iconSize}
      />
      <span
        className={classNames({
          'visually-hidden': !props.showLabel && !props.tooltip,
          [styles['button__tooltip']]: props.tooltip
        })}
      >
        {props.label}
      </span>
    </button>
  );
};
