import React, {
  FunctionComponent,
  useRef,
  useEffect,
  useCallback,
  useContext
} from 'react';
import styles from './styles.module.scss';
import { isSafari } from 'react-device-detect';
import VisualizerFallback from './VisualizerFallback';
import classNames from 'classnames';
import { useReducedMotion } from 'framer-motion';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useAudio } from 'features/audio/audio';
import {
  AnalyserNode,
  MediaElementAudioSourceNode
} from 'standardized-audio-context';

interface VisualizerProps {
  isMini?: boolean;
}

let frequencyData: any;
const mediaElementNodes: any = new WeakMap();

export const Visualizer: FunctionComponent<VisualizerProps> = (props) => {
  const isAnimatingLayout = useSelector(
    (state: RootState) => state.ui.isAnimatingLayout
  );
  const { isNativeAppWebview } = useContext(UserAgentContext);

  const requestRef = useRef<number>();
  const visualizerRef = useRef<HTMLDivElement>(null);
  const analyserRef = useRef<AnalyserNode<any> | null>(null);
  const { audio, audioCtx } = useAudio();
  const playing = useSelector((state: RootState) => state.audio.playing);
  const numberOfBars = props.isMini ? 3 : 200;

  const renderFrame = useCallback(() => {
    const drawBars = (values: [number]) => {
      for (let i = 0; i < numberOfBars; i++) {
        const bar = document.querySelector<HTMLInputElement>('#bar' + i);

        if (!bar) {
          continue;
        }

        const barScale = Math.max(0.04, Math.floor(values[i]) / 2.5 / 100 || 0);
        bar.style.transform = `scaleY(${barScale})`;
      }
    };

    const analyser = analyserRef.current;
    if (analyser) {
      analyser.getByteFrequencyData(frequencyData);
      drawBars(frequencyData);
      requestRef.current = requestAnimationFrame(renderFrame);
    }
  }, [numberOfBars]);

  useEffect(() => {
    if (playing && audio && audioCtx && visualizerRef.current) {
      let audioSourceNode: MediaElementAudioSourceNode<any>;
      const analyser = audioCtx.createAnalyser();

      if (mediaElementNodes.has(audio)) {
        audioSourceNode = mediaElementNodes.get(audio);
      } else {
        audioSourceNode = audioCtx.createMediaElementSource(audio);
        analyser.connect(audioCtx.destination);
        mediaElementNodes.set(audio, audioSourceNode);
      }

      audioSourceNode.connect(analyser);
      analyserRef.current = analyser;
      audioCtx.resume();

      const bufferLength = analyser.frequencyBinCount;
      frequencyData = new Uint8Array(bufferLength);
      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = props.isMini ? 1024 : 2048;

      visualizerRef.current.innerHTML = '';
      for (let i = 0; i < numberOfBars; i++) {
        const bar = document.createElement('div');
        bar.setAttribute('id', 'bar' + i);
        bar.setAttribute('class', 'visualizer-container__bar');
        visualizerRef.current.appendChild(bar);
      }

      renderFrame();

      return () => {
        if (requestRef.current) {
          cancelAnimationFrame(requestRef.current);
        }
      };
    }
  }, [
    playing,
    audioCtx,
    isAnimatingLayout,
    audio,
    numberOfBars,
    props.isMini,
    renderFrame
  ]);

  // If user has reduced motion turned on, no need to render animation
  const shouldReduceMotion = useReducedMotion();
  if (shouldReduceMotion) {
    return null;
  }

  // createMediaElementSource for live streaming is broken in Safari
  // render a fallback CSS visualization
  if (isSafari) {
    return (
      <VisualizerFallback isMini={props.isMini} numberOfBars={numberOfBars} />
    );
  }

  if (isNativeAppWebview || !audio) {
    return null;
  }

  return (
    <AnimatePresence>
      {!isAnimatingLayout && (
        <motion.div
          ref={visualizerRef}
          className={classNames(styles['player-visualizer'], {
            [styles['player-visualizer--mini']]: props.isMini
          })}
          initial={{ opacity: 0 }}
          animate={{ opacity: props.isMini ? 1 : 0.8 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        ></motion.div>
      )}
    </AnimatePresence>
  );
};
