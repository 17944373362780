import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback
} from 'react';
import { RecordingModel } from 'features/recordings/RecordingModel';
import paginationStyles from 'components/Pagination/styles.module.scss';
import { useMediaQuery } from 'react-responsive';
import { Card } from 'components/Card';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { Loader } from 'components/Loader';
import { useSubdomain } from 'hooks/useSubdomain';
import { fetchCollectionRecordings } from 'features/collections/collectionsSlice';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';
import {
  allPublicAndUnlistedCollectionRecordingsSelector,
  collectionRecordingsPaginationStartIds,
  collectionRecordingsPageCount
} from 'features/collections/collectionsSelectors';
import { useCurrentCollection } from 'features/collections/collectionHooks';
import { useAppSelector } from 'hooks/redux';
import { EmptyCollectionRecordings } from './EmptyCollectionRecordings';

export interface PaginatedCollectionRecordingsProps {
  itemsPerPage: number;
}

export const PaginatedCollectionRecordings: FunctionComponent<
  PaginatedCollectionRecordingsProps
> = ({ itemsPerPage }) => {
  const [currentItems, setCurrentItems] = useState<RecordingModel[]>([]);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const loading = useAppSelector(
    (state) => state.collections.collectionRecordingsLoading
  );
  const subdomain = useSubdomain();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPage: number = +(useParams().page || 1);
  const collection = useCurrentCollection();
  const recordings: RecordingModel[] = useSelector(
    allPublicAndUnlistedCollectionRecordingsSelector
  );
  const recordingsPaginationStartIds = useSelector(
    collectionRecordingsPaginationStartIds
  );
  const recordingsPageCount = useSelector(collectionRecordingsPageCount);

  const onPageChange = (event: { selected: number }) => {
    if (!collection) return;
    const scrollToOptions = { top: 0, left: 0, behavior: 'instant' };
    // https://github.com/microsoft/TypeScript/issues/47441#issuecomment-1516836705
    window.scrollTo(scrollToOptions as unknown as ScrollToOptions);
    navigate(`../collections/${collection.id}/page/${event.selected + 1}`);
  };

  const getRecordingSet = useCallback(
    (paginationStartId: number) => {
      const startOffset = recordings
        .map((r) => r.id)
        .indexOf(paginationStartId.toString());
      const endOffset = startOffset + itemsPerPage;
      setCurrentItems(recordings.slice(startOffset, endOffset));
    },
    [itemsPerPage, recordings]
  );

  useEffect(
    function fetchRecordingsOnLoadOrPageChange() {
      if (!collection) return;
      const paginationStartId = recordingsPaginationStartIds.find(
        (p) => p.pageNumber === currentPage
      );
      if (paginationStartId && recordings) {
        getRecordingSet(paginationStartId.recordingStartId);
      } else {
        dispatch(
          fetchCollectionRecordings(
            subdomain,
            collection.id,
            currentPage,
            navigate
          )
        );
      }
    },
    [
      collection,
      currentPage,
      dispatch,
      subdomain,
      recordingsPaginationStartIds,
      recordings,
      getRecordingSet,
      navigate
    ]
  );

  const setInitialPage = currentPage - 1;

  if (recordings.length === 0 && !loading) {
    return <EmptyCollectionRecordings />;
  }

  return (
    <div className="container--wide tw-mt-8">
      {currentItems.length > 0 && (
        <ul className="list--reset grid">
          {currentItems.map((recording) => (
            <li className="col-xss-12 col-xs-6" key={recording.id}>
              <Card
                id={recording.id}
                url={`/recordings/${recording.id}`}
                title={`${recording.title}`}
                artworkKey={recording.artworkKey}
                artworkMode={'recording'}
                type={'recording'}
              />
            </li>
          ))}
        </ul>
      )}
      {recordingsPageCount > 1 && (
        <ReactPaginate
          containerClassName={classNames(paginationStyles['pagination'], {
            [paginationStyles['pagination--loading']]: loading
          })}
          pageClassName={paginationStyles['pagination__page']}
          pageLinkClassName={paginationStyles['pagination__link']}
          activeLinkClassName={paginationStyles['pagination__link--active']}
          previousLinkClassName={paginationStyles['pagination__link--prev']}
          nextLinkClassName={paginationStyles['pagination__link--next']}
          disabledLinkClassName={paginationStyles['pagination__link--disabled']}
          breakLabel="..."
          nextLabel="next ›"
          forcePage={setInitialPage}
          onPageChange={onPageChange}
          marginPagesDisplayed={1}
          pageRangeDisplayed={isMobile ? 2 : 5}
          pageCount={recordingsPageCount}
          previousLabel="‹ prev"
          renderOnZeroPageCount={() => null}
        />
      )}
      {loading && <Loader fillComponent={true} />}
    </div>
  );
};
