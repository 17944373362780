import React, { FunctionComponent } from 'react';
import { SEOHeaderTags } from 'components/SEO/SEOHeaderTags';
import { CollectionModel } from 'features/collections/CollectionModel';
import { useSEOTitleDescription } from 'hooks/useSEOTitleDescription';

interface RecordingHeaderTagsProps {
  collection: CollectionModel | null;
}

export const CollectionHeaderTags: FunctionComponent<
  RecordingHeaderTagsProps
> = ({ collection }) => {
  const { pageTitle, pageDescription } = useSEOTitleDescription(
    collection?.title,
    collection?.description
  );

  if (!collection) {
    return null;
  }

  return (
    <SEOHeaderTags
      title={pageTitle}
      description={pageDescription}
      image={collection.artworkUrl()}
      url={window.location.href}
    />
  );
};
