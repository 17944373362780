import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Icon } from '../Icons';
import styles from './styles.module.scss';

interface PillProps {
  label: any;
  type: string;
  icon?: string;
}

export const Pill: FunctionComponent<PillProps> = (props) => {
  return (
    <div
      className={classNames(styles['pill'], {
        [styles['pill--success']]: props.type === 'success',
        [styles['pill--notify']]: props.type === 'notify',
        [styles['pill--error']]: props.type === 'error'
      })}
    >
      {props.icon && (
        <Icon
          hidden={true}
          icon={props.icon}
          label={props.label}
          height={12}
          width={12}
        />
      )}
      {props.label}
    </div>
  );
};
