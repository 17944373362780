import React, { FunctionComponent, useState, useContext } from 'react';
import styles from './styles.module.scss';
import { useAppSelector } from 'hooks/redux';
import { LogoImage } from 'components/LogoImage';
import { NavLink, Link } from 'react-router-dom';
import { UserAgentContext } from 'features/user-agent/userAgent';
import classNames from 'classnames';
import { ThumbImage } from 'components/Images/ThumbImage';
import { CurrentUserMenu } from 'features/current-user/components/CurrentUserMenu';
import { DarkModeSwitch } from 'components/DarkModeSwitch';
import { toggleSearchVisibility } from 'features/search/searchSlice';
import { useDispatch } from 'react-redux';
import { IconButton } from 'components/Buttons/IconButton';
import { PrivacyNotice } from 'features/privacy/privacyNotice';

export const ChannelHeader: FunctionComponent = () => {
  const [respNavigationShown, setRespNavigationShown] = useState(false);
  const channel = useAppSelector((state) => state.channel.channelDetails);
  const dispatch = useDispatch();

  const { isNativeAppWebview } = useContext(UserAgentContext);

  const toggleMenu = () => {
    setRespNavigationShown(!respNavigationShown);
  };

  const showSearch = () => {
    dispatch(toggleSearchVisibility(true));
  };

  if (!channel || isNativeAppWebview) {
    return null;
  }

  return (
    <header data-testid="header" className={styles['channel-header']}>
      <Link to="/" className={styles['channel-header__logo']}>
        <LogoImage size={'medium'} hasFallback={!channel.logoKey}>
          <ThumbImage
            artworkKey={channel.logoKey}
            artworkMode={'logo'}
            alt={`Logo for ${channel.username}`}
          />
        </LogoImage>
      </Link>
      <button
        aria-controls="navigation"
        className={classNames(styles['channel-header__resp-nav'], {
          [styles['channel-header__resp-nav--expand']]: respNavigationShown
        })}
        onClick={toggleMenu}
      >
        <span>Menu</span>
      </button>
      <nav id="navigation" className={styles['channel-header__nav']}>
        <ul className="list--reset">
          <li>
            <NavLink to="/" state={{ animate: true }}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/events" state={{ animate: true }}>
              Events
            </NavLink>
          </li>
          <li>
            <NavLink to="/recordings" state={{ animate: true }}>
              Recordings
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" state={{ animate: true }}>
              About
            </NavLink>
          </li>
        </ul>
      </nav>
      <IconButton
        label={'Show search'}
        onClick={showSearch}
        icon={'search'}
        iconSize={20}
        noBackground={true}
      />
      <DarkModeSwitch />
      <CurrentUserMenu />
      <PrivacyNotice />
    </header>
  );
};
