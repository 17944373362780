import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventModel } from 'features/events/EventModel';
import {
  upcomingEventSelector,
  liveEventSelector
} from 'features/events/eventsSelectors';
import { useIsEventLive } from 'features/events/eventHooks';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { unloadEvent } from 'thunks/navigationThunks';
import { useDispatch } from 'react-redux';
import { WebSocketContext } from 'features/hub/hub';
import { useLocation, matchPath } from 'react-router-dom';
import { useAudio } from 'features/audio/audio';
import { useOnceEffect } from 'hooks/useOnceEffect';
import { setEmbedPlayerLoaded } from 'features/channel/channelSlice';

export const useChannelEmbedNavigation = () => {
  const navigate = useNavigate();
  const upcomingEvents: EventModel[] = useSelector(upcomingEventSelector);
  const liveEvent: EventModel | null = useSelector(liveEventSelector);
  const isHubConnected = useSelector(
    (state: RootState) => state.hub.isHubConnected
  );
  const autoplay = useSelector(
    (state: RootState) => state.events.autoPlayNextEvent
  );
  const channelHasNavigated = useRef<boolean>(false);
  const dispatch = useDispatch();

  useEffect(
    function navigateToLiveOrUpcomingEventFromChannel() {
      if (!isHubConnected || autoplay || channelHasNavigated.current) return;
      if (liveEvent) {
        const liveEventId = liveEvent.id;
        console.log('[Embed player] - Channel is navigating to live event');
        navigate(`/events/${liveEventId}`, {
          replace: true,
          state: { animate: false }
        });
        channelHasNavigated.current = true;
      } else if (upcomingEvents.length > 0) {
        const upcomingEventId = upcomingEvents[0].id;
        console.log('[Embed player] - Channel is navigating to upcoming event');
        navigate(`/events/${upcomingEventId}`, {
          replace: true,
          state: { animate: false }
        });
        channelHasNavigated.current = true;
      }
      setTimeout(() => {
        dispatch(setEmbedPlayerLoaded(true));
      }, 500);
    },
    [liveEvent, autoplay, isHubConnected, upcomingEvents, navigate, dispatch]
  );
};

export const useChannelEventEmbedNavigation = (event: EventModel | null) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stop } = useAudio();
  const { leaveCrowd } = useContext(WebSocketContext);
  const { pathname } = useLocation();
  const autoplay = useSelector(
    (state: RootState) => state.events.autoPlayNextEvent
  );
  const isEventLive = useIsEventLive(event);
  const upcomingEvents: EventModel[] = useSelector(upcomingEventSelector);
  const liveEvent: EventModel | null = useSelector(liveEventSelector);

  useOnceEffect(function navigateEndOfEvent() {
    if (autoplay) return;
    leaveCrowd();
    stop(true);
    dispatch(unloadEvent());
    if (upcomingEvents.length > 0) {
      const upcomingEventId = upcomingEvents[0].id;
      const upcomingEventPath = matchPath(
        `/events/${upcomingEventId}`,
        pathname
      );
      if (upcomingEventPath) return;
      console.log('[Embed player] - Event navigating to upcoming event');
      navigate(`/events/${upcomingEventId}`, { replace: true });
    } else {
      console.log('[Embed player] - Event navigating to home');
      navigate(`/embed`, { replace: true });
    }
  }, event && (event.isEnded() || !event.isUpcoming()) && !isEventLive);

  useEffect(
    function navigateEventToNextLiveEvent() {
      if (autoplay) return;
      if (liveEvent) {
        const liveEventId = liveEvent.id;
        const liveEventPath = matchPath(`/events/${liveEventId}`, pathname);
        if (liveEventPath) return;
        console.log('[Embed player] - Event is navigating to live event');
        navigate(`/events/${liveEventId}`, { replace: true });
      }
    },
    [pathname, liveEvent, autoplay, navigate]
  );
};
