import { FullImage } from 'components/Images/FullImage';
import { CollectionModel } from 'features/collections/CollectionModel';
import React, { FunctionComponent } from 'react';
import { Share } from 'components/Share';

interface CollectionHeaderProps {
  collection: CollectionModel | null;
}

export const CollectionHeader: FunctionComponent<CollectionHeaderProps> = ({
  collection
}) => {
  if (!collection) {
    return null;
  }

  return (
    <header className="tw-overflow-hidden tw-relative tw-h-[280px] md:tw-h-[400px] tw-flex tw-justify-center tw-items-center">
      <FullImage
        artworkKey={collection.artworkKey}
        artworkMode={'recording'}
        className={
          'tw-absolute tw-object-cover tw-top-0 tw-left-0 tw-right-0 tw-h-full tw-w-full'
        }
        type={'recording'}
      />
      {collection.artworkKey && (
        <div className="tw-absolute tw-left-0 tw-top-0 tw-right-0 tw-bottom-0 tw-bg-black/50"></div>
      )}
      <div
        className={`tw-px-8 md:tw-px-16 tw-relative tw-text-center tw-max-w-4xl tw-mx-auto tw-w-full${
          collection.artworkKey ? ' tw-text-white' : ''
        }`}
      >
        <h2 className="tw-m-0 tw-mb-2 tw-text-3xl md:tw-text-5xl [.ios-listener_&]:tw-text-2xl [.android-listener_&]:tw-text-5xl">
          {collection.title}
        </h2>
        {collection.description && (
          <p className="tw-line-clamp-3 tw-mt-0">{collection.description}</p>
        )}
        <Share title={collection.title} label={'collection'} showLabel={true} />
      </div>
    </header>
  );
};
