import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import styles from './styles.module.scss';
import { Icon } from '../Icons';
import { Loader } from 'components/Loader';
import { PlayerControls } from 'components/Player/PlayerControls';
import { motion, AnimatePresence } from 'framer-motion';

interface PlayerProps {
  showsControls?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  type: 'event' | 'recording';
}

export const Player: FunctionComponent<PlayerProps> = ({
  showsControls,
  onClick,
  type
}) => {
  const loading = useSelector((state: RootState) => state.audio.loading);
  const playing = useSelector((state: RootState) => state.audio.playing);

  const stopOrPause = type === 'event' ? 'Stop' : 'Pause';

  const isAnimatingLayout = useSelector(
    (state: RootState) => state.ui.isAnimatingLayout
  );

  if (isAnimatingLayout) {
    return null;
  }

  return (
    <AnimatePresence>
      {!isAnimatingLayout && (
        <motion.div
          className={classNames(styles['player'], {
            [styles['player--playing']]: playing,
            [styles['player--hidden']]: playing && type === 'event'
          })}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {loading ? (
            <div className={styles['player__button']}>
              <Loader fillComponent={true} isWhite={true} />
            </div>
          ) : (
            <button
              className={styles['player__button']}
              aria-label={playing ? `${stopOrPause} audio` : 'Play audio'}
              onClick={onClick}
            >
              <Icon
                hidden={true}
                icon={playing ? stopOrPause.toLowerCase() : 'play'}
                label={playing ? `${stopOrPause} audio` : 'Play audio'}
              />
            </button>
          )}
          {showsControls && <PlayerControls />}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
