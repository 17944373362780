import React, { FunctionComponent, useCallback } from 'react';
import { cloudflareUrl } from 'utilities/cloudflareImageResizer';
import { ReactComponent as EventBackground } from 'images/event-background.svg';
import { ReactComponent as RecordingBackground } from 'images/recording-background.svg';
import { ReactComponent as PrivacyBackground } from 'images/privacy-background.svg';
import { useArtworkKey } from 'components/Images/imageHooks';

interface FullImageProps {
  artworkKey?: string | null | undefined;
  artworkMode?: string | undefined;
  updatedTimestamp?: string;
  className?: string;
  type: 'event' | 'recording' | 'private';
}

export const FullImage: FunctionComponent<FullImageProps> = ({
  artworkKey,
  artworkMode,
  updatedTimestamp,
  className,
  type
}) => {
  const { imageKey, imageBaseUrl } = useArtworkKey(artworkMode, artworkKey);

  const generateImageUrl = (
    imageSize: number,
    imageDpr: number
  ): string | undefined => {
    let key = imageKey;

    if (updatedTimestamp) {
      key = `${key}?ts=${updatedTimestamp}`;
    }

    return (
      cloudflareUrl(key, {
        width: imageSize,
        quality: 80,
        dpr: imageDpr,
        baseUrl: imageBaseUrl
      }) || undefined
    );
  };

  const renderFallbackArtwork = useCallback(() => {
    switch (type) {
      case 'event':
        return <EventBackground />;
      case 'recording':
        return <RecordingBackground />;
      case 'private':
        return <PrivacyBackground />;
      default:
        return null;
    }
  }, [type]);

  if (!imageKey || artworkMode === 'theme') {
    return renderFallbackArtwork();
  }

  return (
    <picture>
      <source
        media="(min-width: 1441px)"
        srcSet={`
          ${generateImageUrl(2000, 1)} 1x,
          ${generateImageUrl(2000, 2)} 2x
        `}
      />
      <source
        media="(min-width: 900px)"
        srcSet={`
          ${generateImageUrl(1440, 1)} 1x,
          ${generateImageUrl(1440, 2)} 2x
        `}
      />
      <source
        media="(min-width: 601px)"
        srcSet={`
          ${generateImageUrl(900, 1)} 1x,
          ${generateImageUrl(900, 2)} 2x
        `}
      />
      <source
        srcSet={`
        ${generateImageUrl(600, 1)} 1x,
        ${generateImageUrl(600, 2)} 2x
      `}
      />
      <img
        srcSet={`
          ${generateImageUrl(600, 1)} 600w,
          ${generateImageUrl(900, 1)} 900w,
          ${generateImageUrl(1440, 1)} 1440w,
          ${generateImageUrl(2000, 1)} 1440w
        `}
        src={`${generateImageUrl(600, 1)}`}
        alt=""
        className={className}
      />
    </picture>
  );
};
