import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import { Event } from 'features/events/eventsSlice';
import { Card } from 'components/Card';

interface SearchResultEventProps {
  lastElement: boolean;
  result: Event;
}

const SearchResultEvent: ForwardRefRenderFunction<
  HTMLLIElement,
  SearchResultEventProps
> = (props, ref) => {
  return (
    <li className="col-xss-12 col-xs-6" ref={props.lastElement ? ref : null}>
      <Card
        id={props.result.id}
        url={`/events/${props.result.id}`}
        title={props.result.title}
        artworkKey={props.result.artworkKey}
        artworkMode={props.result.artworkMode}
        type={'event'}
      />
    </li>
  );
};

export default forwardRef<HTMLLIElement, SearchResultEventProps>(
  SearchResultEvent
);
