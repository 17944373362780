import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { IconButton } from '../Buttons/IconButton';
import { useAudioPosition } from 'features/audio/useAudioPosition';
import { useAudio } from 'features/audio/audio';

export const RewindButton: FunctionComponent = () => {
  const { seek } = useAudioPosition();
  const { audio } = useAudio();
  const loading = useSelector((state: RootState) => state.audio.loading);

  if (!audio) {
    return null;
  }

  const onClick = () => {
    seek(audio.currentTime - 15);
  };

  return (
    <IconButton
      label={'Rewind 15 seconds'}
      onClick={onClick}
      isDisabled={loading}
      icon={'rewind15'}
    />
  );
};
