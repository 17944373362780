import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { Icon } from '../Icons';
import classNames from 'classnames';

interface ToggleSwitchProps {
  label?: string;
  checked: boolean;
  onIcon?: string;
  offIcon?: string;
  cssClass?: string;
  onChange: (event: React.SyntheticEvent<EventTarget>) => void;
}

export const ToggleSwitch: FunctionComponent<ToggleSwitchProps> = (props) => {
  const { label, checked, onChange } = props;
  return (
    <div
      className={classNames(styles['toggle-switch'], {
        [styles['toggle-switch--on']]: checked
      })}
    >
      <label className={styles['toggle-switch--label']}>
        {label && label}
        {props.onIcon && props.offIcon && (
          <Icon
            hidden={true}
            icon={checked ? props.onIcon : props.offIcon}
            label={props.label ?? ''}
            height={12}
            width={12}
          />
        )}
        <input
          className={styles['toggle-switch--input']}
          type="checkbox"
          onChange={onChange}
          checked={checked}
        />
        <span
          aria-hidden="true"
          className={classNames(
            styles['toggle-switch--slider'],
            props.cssClass ? props.cssClass : ''
          )}
        ></span>
      </label>
    </div>
  );
};
