import { Loader } from 'components/Loader';
import { fetchChatHistory, setChatVisible } from 'features/chat/chatSlice';
import { useIsBroadcaster, useCurrentUser } from 'features/current-user/hooks';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { AnimatePresence } from 'framer-motion';
import {
  ChatScrollWrapper,
  ChatAnimation,
  ChatInput,
  ChatProfile,
  ChatHeader,
  ChatComments,
  ChatLoginPrompt,
  styles
} from './components';
import {
  useRecentMessages,
  useSlidePanelForCrowd
} from 'features/chat/chatHooks';
import { usePageVisibility } from 'hooks/usePageVisibility';
import { Crowd } from 'features/crowd/Crowd';
import { useCurrentEvent } from 'features/events/eventHooks';

interface ChatProps {
  fetchHistory: boolean;
}

export const Chat: FunctionComponent<ChatProps> = ({ fetchHistory }) => {
  const isLoaded = useSelector((state: RootState) => state.chat.isLoaded);
  const isVisible = useSelector((state: RootState) => state.chat.isVisible);
  const slidePanelForCrowd = useSlidePanelForCrowd();
  const messages = useRecentMessages();
  const isPageVisible = usePageVisibility();

  const channelId = useSelector(
    (state: RootState) => state.channel.channelDetails?.ownerId
  );

  const currentUser = useCurrentUser();
  const isBroadcaster = useIsBroadcaster();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const event = useCurrentEvent();

  useEffect(() => {
    if (width >= 740) {
      dispatch(setChatVisible(isVisible));
    }
  }, [dispatch, width, isVisible]);

  useEffect(() => {
    if (channelId && fetchHistory && isPageVisible) {
      dispatch(fetchChatHistory(channelId));
    }
  }, [dispatch, channelId, fetchHistory, isPageVisible]);

  if (event && !event.chatEnabled) {
    return null;
  }

  const renderInner = () => {
    if (!isLoaded || !channelId) {
      return <Loader fillComponent={true} />;
    }

    return (
      <div className={styles['chat__wrapper']}>
        <ChatHeader />
        <ChatScrollWrapper>
          {messages.length > 0 ? (
            <ChatComments messages={messages} />
          ) : (
            <div className={styles['chat__empty']}>
              <h4>
                {currentUser && isBroadcaster
                  ? 'Chat about your live audio'
                  : "Like what you're hearing?"}
              </h4>
              <p>
                {currentUser && isBroadcaster
                  ? 'Tell visitors what they’re\n listening to, say hello 👋'
                  : 'Send a heart, say hello 👋'}
              </p>
            </div>
          )}
        </ChatScrollWrapper>
        {currentUser ? (
          <ChatInput channelId={channelId} />
        ) : (
          <ChatLoginPrompt />
        )}
        {slidePanelForCrowd && <Crowd />}
      </div>
    );
  };

  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <>
          <ChatAnimation>{renderInner()}</ChatAnimation>
          <ChatProfile />
        </>
      )}
    </AnimatePresence>
  );
};
