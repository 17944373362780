import { CardImage } from 'components/Images/CardImage';
import { Collection } from 'features/collections/collectionsSlice';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

export interface CollectionListItemProps {
  collection: Collection;
}

export const CollectionListItem: FunctionComponent<CollectionListItemProps> = ({
  collection
}) => {
  return (
    <article className="tw-overflow-hidden tw-relative tw-h-[180px] md:tw-h-[260px] tw-flex tw-rounded-[12px]">
      <Link
        to={`/collections/${collection.id}`}
        state={{
          prevPath: location.pathname,
          animate: true,
          hideLoader: true
        }}
        className={`tw-flex tw-justify-center tw-items-center tw-flex-1${
          collection.artworkKey
            ? ' tw-text-white hover:tw-text-theme-color'
            : ''
        }`}
      >
        <CardImage
          artworkKey={collection.artworkKey}
          artworkMode={'recording'}
          className={
            'tw-absolute tw-object-cover tw-top-0 tw-left-0 tw-right-0 tw-h-full tw-w-full'
          }
          type={'recording'}
        />
        {collection.artworkKey && (
          <div className="tw-absolute tw-left-0 tw-top-0 tw-right-0 tw-bottom-0 tw-bg-black/50"></div>
        )}
        <div
          className={`tw-relative tw-text-center${
            collection.artworkKey
              ? ' tw-text-white hover:tw-text-theme-color'
              : ''
          }`}
        >
          <h2 className="tw-m-0 tw-text-3xl md:tw-text-5xl [.ios-listener_&]:tw-text-xl [.android-listener_&]:tw-text-3xl">
            {collection.title}
          </h2>
        </div>
      </Link>
    </article>
  );
};
