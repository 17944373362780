import React, { FunctionComponent, useEffect, useContext } from 'react';
import { FullScreenContent } from 'components/Layouts/FullScreenView/FullScreenContent';
import { Visualizer } from 'components/Player/Visualizer';
import { EventModel } from 'features/events/EventModel';
import { EventCountdown } from 'features/events/EventCountdown';
import { useIsEventLive } from 'features/events/eventHooks';
import { EventPlayer } from 'features/events/components/EventPlayer';
import { EventChat } from './EventChat';
import { useAppSelector } from 'hooks/redux';
import { currentBroadcastFinishedSelector } from 'features/broadcast/selectors';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useLocalStorage } from 'usehooks-ts';

interface EventWrapperProps {
  event: EventModel | null;
}

export const EventContent: FunctionComponent<EventWrapperProps> = ({
  event
}) => {
  const isLive = useIsEventLive(event);

  const { postMessage } = useNativeEvents();
  const { isListenerAppWebview } = useContext(UserAgentContext);

  const currentBroadcastFinished = useAppSelector(
    currentBroadcastFinishedSelector
  );
  const [visualizerVisible] = useLocalStorage('visualizer-visible', true);

  useEffect(() => {
    if (isListenerAppWebview) {
      postMessage({
        eventLive: isLive
      });
    }
  }, [isLive, isListenerAppWebview, postMessage]);

  if (!event) {
    return null;
  }

  if (isLive) {
    return (
      <FullScreenContent
        centerColumn={<EventPlayer />}
        visualizer={visualizerVisible && <Visualizer />}
        rightColumn={<EventChat event={event} />}
      />
    );
  } else {
    const rightColumn = currentBroadcastFinished ? (
      <EventChat event={event} />
    ) : null;

    return (
      <FullScreenContent
        centerColumn={<EventCountdown />}
        rightColumn={rightColumn}
      />
    );
  }
};
