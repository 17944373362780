import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback
} from 'react';
import { RecordingModel } from 'features/recordings/RecordingModel';
import paginationStyles from 'components/Pagination/styles.module.scss';
import { useMediaQuery } from 'react-responsive';
import { Card } from 'components/Card';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { Loader } from 'components/Loader';
import { useSubdomain } from 'hooks/useSubdomain';
import { fetchRecordings } from 'features/recordings/recordingsSlice';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';
import { allPublicRecordingsSelector } from 'features/recordings/recordingsSelectors';
import { EmptyRecordings } from 'features/channel/components/ChannelRecordings/EmptyRecordings';
import { Menu } from './Menu';

export interface PaginatedRecordingsProps {
  itemsPerPage: number;
}

export const PaginatedRecordings: FunctionComponent<
  PaginatedRecordingsProps
> = ({ itemsPerPage }) => {
  const [currentItems, setCurrentItems] = useState<RecordingModel[]>([]);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const loading = useSelector((state: RootState) => state.recordings.isLoading);
  const paginationStartIds = useSelector(
    (state: RootState) => state.recordings.paginationStartIds
  );
  const pageCount = useSelector(
    (state: RootState) => state.recordings.pageCount
  );
  const subdomain = useSubdomain();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPage: number = +(useParams().page || 1);
  const channelName = useSelector(
    (state: RootState) => state.channel.channelDetails?.username
  );
  const recordings: RecordingModel[] = useSelector(allPublicRecordingsSelector);

  const onPageChange = (event: { selected: number }) => {
    const scrollToOptions = { top: 0, left: 0, behavior: 'instant' };
    // https://github.com/microsoft/TypeScript/issues/47441#issuecomment-1516836705
    window.scrollTo(scrollToOptions as unknown as ScrollToOptions);
    navigate(`../recordings/page/${event.selected + 1}`);
  };

  const getRecordingSet = useCallback(
    (paginationStartId: number) => {
      const startOffset = recordings
        .map((r) => r.id)
        .indexOf(paginationStartId.toString());
      const endOffset = startOffset + itemsPerPage;
      setCurrentItems(recordings.slice(startOffset, endOffset));
    },
    [itemsPerPage, recordings]
  );

  useEffect(
    function fetchRecordingsOnLoadOrPageChange() {
      const paginationStartId = paginationStartIds.find(
        (p) => p.pageNumber === currentPage
      );
      if (paginationStartId && recordings) {
        getRecordingSet(paginationStartId.recordingStartId);
      } else {
        dispatch(fetchRecordings(subdomain, currentPage, navigate));
      }
    },
    [
      currentPage,
      dispatch,
      subdomain,
      paginationStartIds,
      recordings,
      getRecordingSet,
      navigate
    ]
  );

  const setInitialPage = currentPage - 1;

  if (recordings.length === 0 && !loading) {
    return (
      <>
        <Menu />
        <EmptyRecordings username={channelName || ''} />
      </>
    );
  }

  return (
    <div className="container--wide">
      <Menu />
      <h1 className="tw-sr-only">Recordings</h1>
      {currentItems.length > 0 && (
        <ul className="list--reset grid">
          {currentItems.map((recording) => (
            <li className="col-xss-12 col-xs-6" key={recording.id}>
              <Card
                id={recording.id}
                url={`/recordings/${recording.id}`}
                title={`${recording.title}`}
                artworkKey={recording.artworkKey}
                artworkMode={'recording'}
                type={'recording'}
              />
            </li>
          ))}
        </ul>
      )}
      {pageCount > 1 && (
        <ReactPaginate
          containerClassName={classNames(paginationStyles['pagination'], {
            [paginationStyles['pagination--loading']]: loading
          })}
          pageClassName={paginationStyles['pagination__page']}
          pageLinkClassName={paginationStyles['pagination__link']}
          activeLinkClassName={paginationStyles['pagination__link--active']}
          previousLinkClassName={paginationStyles['pagination__link--prev']}
          nextLinkClassName={paginationStyles['pagination__link--next']}
          disabledLinkClassName={paginationStyles['pagination__link--disabled']}
          breakLabel="..."
          nextLabel="next ›"
          forcePage={setInitialPage}
          onPageChange={onPageChange}
          marginPagesDisplayed={1}
          pageRangeDisplayed={isMobile ? 2 : 5}
          pageCount={pageCount}
          previousLabel="‹ prev"
          renderOnZeroPageCount={() => null}
        />
      )}
      {loading && <Loader fillComponent={true} />}
    </div>
  );
};
