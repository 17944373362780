import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';

export const Menu: FunctionComponent = () => {
  const applyLinkClasses = (isActive: any) => {
    const linkClasses = [
      'tw-block tw-rounded-full tw-px-6 tw-py-2 tw-min-w-[130px] tw-text-center tw-text-black'
    ];
    if (isActive)
      linkClasses.push('tw-bg-theme-color [.dark-contrast_&]:tw-text-white');
    return linkClasses.join(' ');
  };

  return (
    <div className="tw-text-center">
      <ul className="list--reset tw-flex tw-bg-light-background tw-rounded-full tw-inline-flex tw-p-1 tw-mb-4 md:tw-mb-8">
        <li>
          <NavLink
            to="/recordings"
            state={{ animate: false }}
            className={({ isActive }) => applyLinkClasses(isActive)}
          >
            Latest
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/collections"
            state={{ animate: false }}
            className={({ isActive }) => applyLinkClasses(isActive)}
          >
            Collections
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
