import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import classNames from 'classnames';
import styles from './styles.module.scss';
import playerStyles from 'components/Player/styles.module.scss';
import { Pill } from 'components/Pill';
import { EventDate } from 'features/events/EventDate';
import { RecordingDate } from 'features/recordings/components/RecordingDate';
import { useCurrentEvent } from 'features/events/eventHooks';
import { useCurrentRecording } from 'features/recordings/recordingHooks';
import { useMediaQuery } from 'react-responsive';
import { Icon } from '../Icons';
import { CardImage } from 'components/Images/CardImage';
import { FullImage } from 'components/Images/FullImage';
import { LiveNow } from 'components/LiveNow';
import { CardLink } from './CardLink';

interface CardProps {
  id: string;
  title: string;
  url?: string | null;
  artworkKey?: string | null;
  artworkMode?: string | undefined;
  featured?: boolean;
  isPrivate?: boolean;
  isActive?: boolean;
  isFeatured?: boolean;
  type: 'event' | 'recording';
  updatedTimestamp?: string;
}

export const Card: FunctionComponent<CardProps> = (props) => {
  const playing = useSelector((state: RootState) => state.audio.playing);
  const eventIsLive = props.isActive;
  const event = useCurrentEvent();
  const recording = useCurrentRecording();
  const isMobile = useMediaQuery({ query: '(max-width: 739px)' });

  const featuredText =
    props.type === 'event' ? 'Next live event' : 'Latest recording';

  const isPlaying = () => {
    if (
      playing &&
      ((event && event.id === props.id && eventIsLive) ||
        (recording && recording.id === props.id))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isPlayable =
    (props.type === 'recording' || eventIsLive) && !isPlaying();

  const cardContent = (
    <>
      {props.isFeatured ? (
        <FullImage
          artworkKey={props.artworkKey}
          artworkMode={props.artworkMode}
          updatedTimestamp={props.updatedTimestamp}
          className={styles['card__image']}
          type={props.type}
        />
      ) : (
        <CardImage
          artworkKey={props.artworkKey}
          artworkMode={props.artworkMode}
          updatedTimestamp={props.updatedTimestamp}
          className={styles['card__image']}
          type={props.type}
        />
      )}
      <div className={styles['card__content']}>
        <h2 className={classNames(styles['card__title'])}>{props.title}</h2>
        {props.type === 'event' ? (
          <EventDate eventId={props.id} cssClass={styles['card__meta']} />
        ) : (
          <RecordingDate
            recordingId={props.id}
            cssClass={styles['card__meta']}
          />
        )}
      </div>
      {isPlayable && (
        <div
          className={classNames(
            styles['card__play'],
            playerStyles['player__button'],
            {
              [playerStyles['player__button--small']]:
                isMobile || !props.isFeatured,
              [playerStyles['player__button--large']]: props.isFeatured,
              [playerStyles['player__button--playing']]: isPlaying(),
              [styles['card__play--featured']]: props.isFeatured
            }
          )}
        >
          <Icon
            hidden={true}
            icon={isPlaying() ? 'pause' : 'play'}
            label={isPlaying() ? 'Pause audio' : 'Play audio'}
          />
        </div>
      )}

      <div className={styles['card__status']}>
        {props.isPrivate && (
          <Pill label={'Private'} type={'success'} icon={'lock'} />
        )}
        <LiveNow isLive={eventIsLive} />
        {props.isFeatured && !eventIsLive && (
          <Pill label={featuredText} type={'success'} />
        )}
      </div>
    </>
  );

  return (
    <article
      className={classNames(styles['card'], {
        [styles['card--with-image']]:
          (props.artworkKey && props.artworkMode !== 'theme') ||
          props.artworkMode === 'channel',
        [styles['card--event']]: props.featured,
        [styles['card--featured']]: props.isFeatured
      })}
    >
      {props.url ? (
        <CardLink
          id={props.id}
          url={props.url}
          type={props.type}
          autoPlay={isPlayable}
          eventIsLive={eventIsLive}
        >
          {cardContent}
        </CardLink>
      ) : (
        { cardContent }
      )}
    </article>
  );
};
