import React, { FunctionComponent, useContext } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ThemeContext } from 'features/theme/theme';

interface UserImageProps {
  children: React.ReactNode;
  size?: string;
}

export const UserImage: FunctionComponent<UserImageProps> = (props) => {
  const { children, size } = props;
  const { themeGradient } = useContext(ThemeContext);

  return (
    <div
      className={classNames(
        styles['profile-image'],
        { [styles['profile-image--large']]: size === 'large' },
        { [styles['profile-image--medium']]: size === 'medium' },
        { [styles['profile-image--small']]: size === 'small' },
        { [styles['profile-image--mini']]: size === 'mini' }
      )}
      style={children ? {} : { backgroundImage: themeGradient }}
    >
      {children}
    </div>
  );
};
