import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Icon } from '../Icons';

interface LoaderProps {
  fillComponent?: boolean;
  isWhite?: boolean;
  hideOnNative?: boolean;
}

export const Loader: FunctionComponent<LoaderProps> = (props) => {
  if (props.hideOnNative) {
    return null;
  }

  return (
    <div
      className={classNames(styles['loader'], {
        [styles['loader--fill']]: props.fillComponent,
        [styles['loader--white']]: props.isWhite
      })}
    >
      <Icon icon={'loader'} hidden={false} label={'Loading...'} />
    </div>
  );
};
