import React, { FunctionComponent, useContext } from 'react';
import { FullScreenWrapper } from 'components/Layouts/FullScreenView/FullScreenWrapper';
import styles from './styles.module.scss';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { ThumbImage } from 'components/Images/ThumbImage';
import { LogoImage } from 'components/LogoImage';
import classNames from 'classnames';
import { ChannelDeepLinkBanner } from 'features/channel/components/ChannelDeepLinkBanner';
import { matchPath, useLocation } from 'react-router-dom';
import { EventDeepLinkBanner } from 'features/events/components/EventDeepLinkBanner';

interface AccessViewProps {
  children: React.ReactNode;
  artworkKey: string | null;
  logoKey: string | null;
  username: string;
  size?: 'wide' | null;
}

export const AccessView: FunctionComponent<AccessViewProps> = ({
  children,
  artworkKey,
  logoKey,
  username,
  size
}) => {
  const { isEmbed } = useContext(UserAgentContext);
  const { pathname } = useLocation();
  const isEventPath = matchPath('/events/:id', pathname);

  return (
    <div className={styles['access']}>
      {isEventPath ? <EventDeepLinkBanner /> : <ChannelDeepLinkBanner />}
      <FullScreenWrapper
        artworkKey={artworkKey}
        artworkMode={'private'}
        type={'private'}
      >
        <div
          className={classNames(styles['access-modal'], {
            [styles['access-modal--wide']]: size == 'wide'
          })}
        >
          <div className={styles['access-modal__content']}>
            <LogoImage
              size={isEmbed ? 'small' : 'medium'}
              hasFallback={!logoKey}
            >
              <ThumbImage
                artworkKey={logoKey}
                artworkMode={'logo'}
                alt={`Logo for ${username}`}
              />
            </LogoImage>
            {children}
          </div>
        </div>
      </FullScreenWrapper>
    </div>
  );
};
