import { Loader } from 'components/Loader';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import styles from './styles.module.scss';
import { FullImage } from 'components/Images/FullImage';
import parse from 'html-react-parser';
import classNames from 'classnames';

export const ChannelDetails: FunctionComponent = () => {
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const channelisLoaded = useSelector(
    (state: RootState) => state.channel.isLoaded
  );

  if (!channelisLoaded || channel == null) {
    return <Loader fillComponent={true} />;
  }

  return (
    <div
      className={classNames(styles['channel-details'], {
        [styles['channel-details--with-image']]: channel.artworkKey
      })}
    >
      <FullImage
        artworkMode={'channel'}
        className={styles['channel-details__image']}
        type={'event'}
      />
      <div className={styles['channel-details__content']}>
        <h1 className={styles['channel-details__title']}>
          {parse(`<span>Welcome to</span> ${channel.username}`)}
        </h1>
      </div>
    </div>
  );
};
